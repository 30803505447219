export const dialogueData = (lang) => {
  switch (lang) {
    case "en":
      return (
        [
          {
            id: 'dialogue01',
            category: 'dialogue',
            content: (
              <p>
                Hello!
                <br />
                This is a flyer image creation application that allows you to experience 1 to 1 paper marketing. We will
                <span className="accent-color bold"> create an effective flyer image </span>
                for your business!
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue02',
          },
          {
            id: 'dialogue02',
            category: 'dialogue',
            content: (
              <p>
                To begin with, please tell us more about the business you wish to promote and the nature of your offer.
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'formBusiness',
            category: 'form',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03',
            category: 'dialogue',
            content: (
              <p>
                Thank you very much!
                <br />
                We will now consider the customer segments for <span className="business"></span>, so please select up to three of the following categories that are important to you for segmentation.
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formCategory',
          },
          {
            id: 'formCategory',
            category: 'formCategory',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03_2',
            category: 'dialogue',
            content: (
              <p>
                Thank you very much!
                <br />
                We will now go on to propose <span className="primary-color bold">Customer Cards</span> that consolidate all of your customer information.
                <br />
                We will propose up to 20 cards, so if you have any cards that you would like to create flyer images for, please <span className="bold">Add to Customer Card List</span>!
                <br />
                You can also edit the cards yourself.
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue04',
          },
          {
            id: 'dialogue03e',
            category: 'dialogue',
            content: (
              <p>
                Sorry, we could not create the segment properly. Please tell us again from the business description.
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'dialogue04',
            category: 'dialogue',
            content: <p>First of all, how about this customer card from?</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'formSegment',
            category: 'formSegment',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedFirst',
            category: 'dialogue',
            content: (
              <p>
                Added to the list!
                <br />
                You can <span className="accent-color bold">create flyer images</span> at any time by clicking the button below or on the customer card list to the right. Please note that you can only create up to 8 at a time."
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue05',
          },
          {
            id: 'dialogueAdded',
            category: 'dialogue',
            content: <p>Added! Then how about this one?</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogueAddedReload',
            category: 'dialogue',
            content: (
              <p>
                Added!
                <br />
                Once here, please wait a moment as we come up with a new segment card......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'segmentReload',
            category: 'segmentReload',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'segmentReloadSuccess',
            category: 'dialogue',
            content: (
              <p>
                Thank you for waiting! How about this one?
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'segmentReloadError',
            category: 'dialogue',
            content: (
              <p>
                The segment card could not be created successfully.
                <br />
                Please wait a moment while we try to create it again.
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'toDMList',
            category: 'toDMList',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedEnd',
            category: 'dialogue',
            content: (
              <p>
                Added!
                <br />
                This brings our maximum to 8. Please check the list of customer cards on the right to start creating your own!
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassed',
            category: 'dialogue',
            content: <p>How about this one then?</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialoguePassedReload',
            category: 'dialogue',
            content: (
              <p>
                Passed!
                <br />
                Once here, please wait a moment while we come up with a new segment card......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'dialogueAddedFull',
            category: 'dialogue',
            content: (
              <p>
                Added!
                <br />
                This is the only customer card I can provide. Please start creating your flyer image from the customer card list on the right!
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassedFull',
            category: 'dialogue',
            content: (
              <p>
                Yes, sir!
                <br />
                This is the only customer card I can provide. If you like what you see, please start creating your flyer image from the customer card list on the right!
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue05',
            category: 'dialogue',
            content: <p>Then, how about this one?</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogue06',
            category: 'dialogue',
            content: (
              <p>
                Okay, we will create a flyer image based on your customer card!
                <br />
                Please wait a moment......
              </p>
            ),
            nextAction: 'delay',
            nextId: 'DMGenerating',
          },
          {
            id: 'DMGenerating',
            category: 'DMGenerating',
            content: '',
            nextAction: null,
            nextId: null,
          },
        ]
      )
    case "zh":
      return (
        [
          {
            id: 'dialogue01',
            category: 'dialogue',
            content: (
              <p>
                您好！
                <br />
                这是一款传单图像制作应用程序，让您体验 1 对 1 纸质营销。 我们将为您的企业制作更具营销效果的<span className="accent-color bold">传单图像</span>！
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue02',
          },
          {
            id: 'dialogue02',
            category: 'dialogue',
            content: (
              <p>
                首先，请向我们详细介绍您希望推广的业务以及您所提供服务和商品的性质。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'formBusiness',
            category: 'form',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03',
            category: 'dialogue',
            content: (
              <p>
                非常感谢！
                <br />
                现在，我们将为您匹配<span className="business"></span>的客户细分领域，请从以下类别中最多选择三个您认为重要的细分类别。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formCategory',
          },
          {
            id: 'formCategory',
            category: 'formCategory',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03_2',
            category: 'dialogue',
            content: (
              <p>
                非常感谢！
                <br />
                接下来，我们将为您提供将客户信息汇总在一起的<span className="primary-color bold">客户卡片</span>。
                <br />
                最多建议20件，如果有符合制作传单图像的客户卡片的话，请不断<span className="bold">添加到客户卡片列表</span>中！
                <br />
                也可以自己编辑客户卡片。
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue04',
          },
          {
            id: 'dialogue03e',
            category: 'dialogue',
            content: (
              <p>
                很抱歉，我们无法正确完成该细分类别。 请再次告知您的服务、产品内容。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'dialogue04',
            category: 'dialogue',
            content: <p>首先……这张客户卡片怎么样？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'formSegment',
            category: 'formSegment',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedFirst',
            category: 'dialogue',
            content: (
              <p>
                已添加到列表中！
                <br />
                您可以使用下面的按钮或右侧的客户卡片列表随时<span className="accent-color bold">创建传单图像</span>。 请注意，一次最多只能创建 8 张。
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue05',
          },
          {
            id: 'dialogueAdded',
            category: 'dialogue',
            content: <p>已经添加！ 您看这张怎么样？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogueAddedReload',
            category: 'dialogue',
            content: (
              <p>
                已经添加！
                <br />
                正在重新生成新的属性卡，请稍候......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'segmentReload',
            category: 'segmentReload',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'segmentReloadSuccess',
            category: 'dialogue',
            content: (
              <p>
                感谢您的等待！ 这张怎么样？
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'segmentReloadError',
            category: 'dialogue',
            content: (
              <p>
                无法成功创建属性卡。
                <br />
                请稍等片刻，再次尝试重新创建。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'toDMList',
            category: 'toDMList',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedEnd',
            category: 'dialogue',
            content: (
              <p>
                已经添加！
                <br />
                因为最大只能做成8张卡片，请确认右侧客户卡片片列表后再次执行该操作！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassed',
            category: 'dialogue',
            content: <p>那这张卡片怎么样？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialoguePassedReload',
            category: 'dialogue',
            content: (
              <p>
                成功通过！
                <br />
                正在生成新的属性卡，请稍候......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'dialogueAddedFull',
            category: 'dialogue',
            content: (
              <p>
                已经添加！
                <br />
                以上是能您提供的客户卡片。 从右侧的客户卡片列表中开始创建您的传单图像！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassedFull',
            category: 'dialogue',
            content: (
              <p>
                已经知道了！
                <br />
                以上是能您提供的客户卡片。如果有您喜欢的，请从右侧的客户卡片列表中开始创建您的传单图像！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue05',
            category: 'dialogue',
            content: <p>接下来，这张怎么样？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogue06',
            category: 'dialogue',
            content: (
              <p>
                正在根据您选定的客户卡片创建传单图像！
                <br />
                请稍候 ......
              </p>
            ),
            nextAction: 'delay',
            nextId: 'DMGenerating',
          },
          {
            id: 'DMGenerating',
            category: 'DMGenerating',
            content: '',
            nextAction: null,
            nextId: null,
          },
        ]
      )
    case "ja":
    default:
      return (
        [
          {
            id: 'dialogue01',
            category: 'dialogue',
            content: (
              <p>
                こんにちは！
                <br />
                こちらは1to1の紙マーケティングを体験できる、チラシイメージ作成アプリケーションです。あなたのビジネスに効果的な
                <span className="accent-color bold">チラシイメージの作成</span>
                をいたします！
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue02',
          },
          {
            id: 'dialogue02',
            category: 'dialogue',
            content: (
              <p>
                まずはじめに、あなたがプロモーションしたいビジネスとオファー内容について詳しく教えてください。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'formBusiness',
            category: 'form',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03',
            category: 'dialogue',
            content: (
              <p>
                ありがとうございます！
                <br />
                続いて<span className="business"></span>の顧客セグメントを考えてまいりますので、
                セグメント分割する上で重要視したいカテゴリがあれば、以下から3つまでお選びください。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formCategory',
          },
          {
            id: 'formCategory',
            category: 'formCategory',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue03_2',
            category: 'dialogue',
            content: (
              <p>
                ありがとうございます！
                <br />
                それでは続いて、顧客情報をひとまとめにした
                <span className="primary-color bold">顧客カード</span>
                を提案していきます。
                <br />
                最大20件提案しますので、チラシイメージを作ってみたいカードがありましたらどんどん
                <span className="bold">顧客カードリストに追加</span>してください！
                <br />
                カードをご自身で編集することも可能です。
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue04',
          },
          {
            id: 'dialogue03e',
            category: 'dialogue',
            content: (
              <p>
                申し訳ありません、セグメントの作成がうまくできませんでした。もう一度ビジネス内容から教えてください。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formBusiness',
          },
          {
            id: 'dialogue04',
            category: 'dialogue',
            content: <p>まずはじめに……こちらの顧客カードはいかがでしょうか？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'formSegment',
            category: 'formSegment',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedFirst',
            category: 'dialogue',
            content: (
              <p>
                リストに追加しました！
                <br />
                <span className="accent-color bold">チラシイメージの作成</span>
                は、下のボタンか右の顧客カードリストからいつでも行えます。一度に作れるのは最大8件ですのでご注意ください。
              </p>
            ),
            nextAction: 'delay',
            nextId: 'dialogue05',
          },
          {
            id: 'dialogueAdded',
            category: 'dialogue',
            content: <p>追加しました！続いてこちらはいかがでしょうか？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogueAddedReload',
            category: 'dialogue',
            content: (
              <p>
                追加しました！
                <br />
                ここでいったん、新しいセグメントカードを考えますので、少しお待ちください......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'segmentReload',
            category: 'segmentReload',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'segmentReloadSuccess',
            category: 'dialogue',
            content: (
              <p>
                お待たせしました！こちらはいかがでしょうか？
              </p>
            ),
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'segmentReloadError',
            category: 'dialogue',
            content: (
              <p>
                セグメントカードの作成がうまくできませんでした。
                <br />
                もう一度作成してみますのでしばらくお待ちください。
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'toDMList',
            category: 'toDMList',
            content: '',
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogueAddedEnd',
            category: 'dialogue',
            content: (
              <p>
                追加しました！
                <br />
                これで最大の8件になりましたので、右の顧客カードリストをご確認の上作成を始めてください！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassed',
            category: 'dialogue',
            content: <p>それではこちらはいかがでしょうか？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialoguePassedReload',
            category: 'dialogue',
            content: (
              <p>
                パスしました！
                <br />
                ここでいったん、新しいセグメントカードを考えますので、少しお待ちください......
              </p>
            ),
            nextAction: 'auto',
            nextId: 'segmentReload',
          },
          {
            id: 'dialogueAddedFull',
            category: 'dialogue',
            content: (
              <p>
                追加しました！
                <br />
                私が出せる顧客カードはここまでになります。右の顧客カードリストからチラシイメージ作成を始めてください！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialoguePassedFull',
            category: 'dialogue',
            content: (
              <p>
                承知しました！
                <br />
                私が出せる顧客カードはここまでになります。気に入ったものがありましたら、右の顧客カードリストからチラシイメージ作成を始めてください！
              </p>
            ),
            nextAction: null,
            nextId: null,
          },
          {
            id: 'dialogue05',
            category: 'dialogue',
            content: <p>続いて、こちらはいかがでしょうか？</p>,
            nextAction: 'auto',
            nextId: 'formSegment',
          },
          {
            id: 'dialogue06',
            category: 'dialogue',
            content: (
              <p>
                それでは、顧客カードをもとにチラシイメージを作成します！
                <br />
                しばらくお待ちください......
              </p>
            ),
            nextAction: 'delay',
            nextId: 'DMGenerating',
          },
          {
            id: 'DMGenerating',
            category: 'DMGenerating',
            content: '',
            nextAction: null,
            nextId: null,
          },
        ]
      )
  }
}
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * 画面遷移時トップに移動するコンポーネント
 * @function
 * @returns {JSX.Element}
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

/**
 * フッターのコンポーネント
 * @function
 * @returns {JSX.Element}
 */
export const Footer = () => {
  return (
    <>
      <footer className="footer bg-dark p-3 text-white">
        <div className="container">
          <p className="text-center m-0">© 2023 Konica Minolta, Inc.</p>
        </div>
      </footer>
    </>
  );
};

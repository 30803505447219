const buttonStyle = {
  'primary-fill': 'primary-fill',
  'primary-line': 'primary-line',
  'alert-line': 'alert-line',
  'secondary-fill': 'secondary-fill',
  'secondary-line': 'secondary-line',
  'accent-fill': 'accent-fill',
  'disabled-fill': 'disabled-fill',
  'disabled-line': 'disabled-line',
  'primary-line-round': 'primary-line-round',
  'secondary-fill-round': 'secondary-fill-round',
  'secondary-line-round': 'secondary-line-round',
  'alert-line-round': 'alert-line-round',
  'accent-fill-round': 'accent-fill-round',
  'disabled-fill-round': 'disabled-fill-round',
  'disabled-line-round': 'disabled-line-round',
  'clear-line-round': 'clear-line-round',
};
const sizeStyle = {
  small: 'small',
  big: 'big',
};

/**
 * ボタンコンポーネント
 * @function
 * @param {ReactNode} children 子要素
 * @param {string} variant ボタンスタイル
 * @param {string} size ボタンサイズ
 * @param {string} className CSSクラス名
 * @returns {JSX.Element}
 */
export const Button = ({ children, variant, size, className, ...props }) => {
  return (
    <button
      className={`button-style ${buttonStyle[variant]} ${sizeStyle[size]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

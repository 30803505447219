export const tips = (lang) => {
    switch (lang) {
        case "en":
            return (
                [
                    {
                        text: <p>Studies have shown that<br/>"the weight of a letter can change the degree of gratitude conveyed".</p>,
                        image: "1.png",
                    },
                    {
                        text: <p>Using analog paper media makes it easier<br/>for customers to remember you.</p>,
                        image: "2.png",
                    },
                    {
                        text: <p>Including a flyer with arranged recipes and detailed nutritional information<br/>about the foods purchased can promote repeat business.</p>,
                        image: "3.png",
                    },
                    {
                        text: <p>It is 1to1 paper marketing that<br/>improves the quality, not the quantity, of customer contacts.</p>,
                        image: "4.png",
                    },
                    {
                        text: <p>Return On Advertising Spend (ROAS) is<br/>a cost-effectiveness measure based on sales obtained relative to advertising expenditures.<br/>ROAS (%) = sales via advertising ÷ advertising spend x 100.</p>,
                        image: "5.png",
                    },
                    {
                        text: <p>Include a greeting/thank you letter<br/>with your initial purchase to show your appreciation.</p>,
                        image: "6.png",
                    },
                    {
                        text: <p>By building personalized relationships with your target users,<br/>you can maximize customer satisfaction with effective marketing messages.</p>,
                        image: "7.png",
                    },
                    {
                        text: <p>By making it personalized,<br/>we can tailor the offer to the customer's needs and preferences.</p>,
                        image: "8.png",
                    },
                    {
                        text: <p>To create a unique impression,<br/>marketing with paper is effective.</p>,
                        image: "9.png",
                    },
                    {
                        text: <p>Special messages and designs provide a different tactile and visual effect<br/>than digital advertising and increase brand awareness.</p>,
                        image: "10.png",
                    },
                    {
                        text: <p>We provide a memorable experience<br/>with uniqueness and tactility.</p>,
                        image: "11.png",
                    },
                    {
                        text: <p>"Sending a letter directly to a customer provides a personalized approach to the customer.<br/>It gives a sense of familiarity and specialness, and has the effect of deepening the connection with your customers."</p>,
                        image: "12.png",
                    },
                ]
            )
        case "zh":
            return (
                [
                    {
                        text: <p>【通过纸张信件形式改变表达方式，从而让客户感受更多的不同程度的感激之情】<br/>的研究成果</p>,
                        image: "1.png",
                    },
                    {
                        text: <p>使用传统的纸质媒体<br/>让客户更容易记住您</p>,
                        image: "2.png",
                    },
                    {
                        text: <p>提供【食谱和有关所购食品的详细营养信息】<br/>包括食品概要的传单，可望鼓励重复购买的效果</p>,
                        image: "3.png",
                    },
                    {
                        text: <p>提高的不是与客户接触的 "次数"，而是接触的 "质量"<br/>1to1 纸质营销</p>,
                        image: "4.png",
                    },
                    {
                        text: <p>什么是广告支出回报率（ROAS）？<br/>ROAS（广告支出回报率）是根据广告支出产生的销售额来衡量成本效益的指标<br/>ROAS (%) = 广告销售额 ÷ 广告支出 x 100</p>,
                        image: "5.png",
                    },
                    {
                        text: <p>在首次购买时附上一封问候信/感谢信<br/>表达您的感激之情！</p>,
                        image: "6.png",
                    },
                    {
                        text: <p>与目标用户建立个性化关系，<br/>利用有效的营销信息能最大限度地提高客户满意度</p>,
                        image: "7.png",
                    },
                    {
                        text: <p>为客户提供个性化服务，<br/>满足不同的需求和偏好的建议</p>,
                        image: "8.png",
                    },
                    {
                        text: <p>为了给人留下独特的印象，<br/>利用纸质媒体的营销也是非常有效的</p>,
                        image: "9.png",
                    },
                    {
                        text: <p>个性化的信息和设计，<br/>为客户提供不同于数字广告的触觉和视觉效果，从而提升品牌知名度</p>,
                        image: "10.png",
                    },
                    {
                        text: <p>具有独特性和纸质的触感、<br/>为客户提供难忘的体验</p>,
                        image: "11.png",
                    },
                    {
                        text: <p>直接致函客户，为客户提供个性化服务<br/>给人一种熟悉和专业的感觉，从而加深与客户的联系</p>,
                        image: "12.png",
                    },
                ]
            )
        case "ja":
        default:
            return (
                [
                    {
                        text: <p>「手紙の重さで感謝の伝わり度合いが変わる」<br/>という研究結果がある</p>,
                        image: "1.png",
                    },
                    {
                        text: <p>アナログな紙媒体を使うことで<br/>顧客の記憶に残りやすくなる</p>,
                        image: "2.png",
                    },
                    {
                        text: <p>購入した食品に関するアレンジレシピや詳細な栄養情報などを<br/>まとめたチラシを同梱すると、リピート促進が期待できる</p>,
                        image: "3.png",
                    },
                    {
                        text: <p>顧客接点の「量」ではなく「質」を高めるのが<br/>1to1紙マーケティング</p>,
                        image: "4.png",
                    },
                    {
                        text: <p>ROAS（Return On Advertising Spend：広告費用対効果）とは、<br/>広告費に対して得られた売上高を基に費用対効果を測る指標です<br/>ROAS（％）＝広告を経由した売上額÷広告費×100</p>,
                        image: "5.png",
                    },
                    {
                        text: <p>初回購入時に、挨拶状・サンキューレターを同梱して<br/>感謝を伝えましょう</p>,
                        image: "6.png",
                    },
                    {
                        text: <p>ターゲットユーザーとの個別の関係構築により、効果的な<br/>マーケティングメッセージで顧客満足度を最大化できます</p>,
                        image: "7.png",
                    },
                    {
                        text: <p>パーソナライズにすることで顧客のニーズや好みに合わせた<br/>提案ができます</p>,
                        image: "8.png",
                    },
                    {
                        text: <p>独特な印象を与えるために、<br/>紙を使ったマーケティングは有効です</p>,
                        image: "9.png",
                    },
                    {
                        text: <p>特別なメッセージやデザインでデジタル広告とは異なる手触りと<br/>視覚効果を提供し、ブランド認知度を向上させます</p>,
                        image: "10.png",
                    },
                    {
                        text: <p>独自性と手触りの良さで、<br/>心に残る体験を提供します</p>,
                        image: "11.png",
                    },
                    {
                        text: <p>直接手紙を送ることで、お客様への個別のアプローチができます<br/>親しみやすさや特別感を与え、顧客とのつながりを深める効果があります</p>,
                        image: "12.png",
                    },
                ]
            )
    }
}
import { useState } from 'react';

import { Button } from './Button';

export const TextCompare = ({ DMGenerateList, DMContentsList, t }) => {
  const [isTextCompareOpen, setIsTextCompareOpen] = useState(false);
  const [textComparePage, setTextComparePage] = useState(0);

  const handlePageLeft = () => {
    textComparePage > 0 && setTextComparePage((prev) => prev - 1);
  };
  const handlePageRight = () => {
    textComparePage < DMGenerateList.length - 3 &&
      setTextComparePage((prev) => prev + 1);
  };

  return (
    <div className="text-compare">
      <Button
        className="compare-button"
        variant="primary-line-round"
        onClick={() => setIsTextCompareOpen(true)}
      >
        {t("result.compare")}
        <img src="images/search.svg" alt=""></img>
      </Button>
      <div className={`modal-area ${isTextCompareOpen ? 'open' : ''}`}>
        <div className="contents d-flex">
          {DMGenerateList.map((contents, key) => {
            const activePage = [
              textComparePage,
              textComparePage + 1,
              textComparePage + 2,
            ];

            if (DMContentsList[key]) {
              return (
                <div
                  key={key}
                  className={`DM ${activePage.includes(key) ? 'active' : ''}`}
                >
                  <div className="DMcard">
                    <div className="segment">
                      <img src="images/person.svg" alt="" />
                      <span>{contents.segment}</span>
                    </div>
                    <div className="tag">
                      {contents.tags.map((tag, key) => (
                        <div key={key} className="tag-wrap">
                          <div className="tag-text">{tag}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text">
                    <p className="header">
                      <span>{t("result.salutation")}</span>
                      {DMContentsList[key].salutation}
                    </p>
                    <p>{DMContentsList[key].staffComment}</p>
                    <p className="header">
                      <span>{t("result.topicA")}</span>
                      {DMContentsList[key].headline01}
                    </p>
                    <p>{DMContentsList[key].text01}</p>
                    <p className="header">
                      <span>{t("result.topicB")}</span>
                      {DMContentsList[key].headline02}
                    </p>
                    <p>{DMContentsList[key].text02}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={key}
                  className={`DM ${activePage.includes(key) ? 'active' : ''}`}
                >
                  <div className="DMcard">
                    <div className="segment">
                      <img src="images/person.svg" alt="" />
                      <span>{contents.segment}</span>
                    </div>
                    <div className="tag">
                      {contents.tags.map((tag, key) => (
                        <div key={key} className="tag-wrap">
                          <div className="tag-text">{tag}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="container">
                    <div className="circle"></div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="buttons d-flex">
          <Button
            className={`left ${textComparePage > 0 ? 'active' : ''}`}
            variant="clear-line-round"
            onClick={() => handlePageLeft()}
          >
            <span></span>
            <span></span>
          </Button>
          <Button
            variant="clear-line-round"
            onClick={() => setIsTextCompareOpen(false)}
          >
            {t("result.close")}
          </Button>
          <Button
            className={`right ${textComparePage < DMGenerateList.length - 3 ? 'active' : ''}`}
            variant="clear-line-round"
            onClick={() => handlePageRight()}
          >
            <span></span>
            <span></span>
          </Button>
        </div>
      </div>
    </div>
  );
};

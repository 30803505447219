import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Chat } from './features/chat/Chat';
import { DMList } from './features/DMlist/DMList';
import { NoMatch } from './routes/NoMatch';
import { ScrollToTop } from './components/ScrollToTop';
import { ThumbnailLayoutArea } from './features/chat/generateDM/ThumbnailLayoutArea';

import './style/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [DMContentsList, setDMContentsList] = useState([]); //DM作成リスト

  return (
    <div className="App grafaiai-app">
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route index element={<Chat DMContentsList={DMContentsList} setDMContentsList={setDMContentsList} />} />
          <Route path="/result" element={<DMList DMContentsList={DMContentsList} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </main>
      <Footer />
      <ThumbnailLayoutArea DMContentsList={DMContentsList} />
    </div>
  );
}

export default App;

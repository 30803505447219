import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

/**
 * DM一覧画面のDMを表示するコンポーネント
 * @param {*} id ソートのための固有ID
 * @param {*} contents DMのコンテンツ
 * @param {*} index DMの順番
 * @param {*} handleSelectDM DM選択処理の関数
 * @param {*} isSelect 選択の有無を保持する真偽値
 * @returns {JSX.Element}
 */
export function DMListItem({ id, contents, index, handleSelectDM, isSelect, enabled }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: !enabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <div
      className="DM"
      key={index}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="DM-thumbnail" onClick={() => handleSelectDM(index)}>
        <img src={contents.thumbnail || "images/blank.png"} alt="" />
        <div className={isSelect ? 'DM-thumbnail-select-area' : null}></div>
        <div className={`container ${contents.thumbnail ? "" : "active"}`}>
          <div className="circle"></div>
        </div>
      </div>

      <div className="DM-information">
        <p className="segment-text">
          <img src="images/person.svg" alt="" />
          {contents.segmentText}
        </p>
        <div className="tags">
          {contents.tags.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
            </div>
          ))}
        </div>
        <p className="business-summary">{contents.businessSummary}</p>
      </div>
    </div>
  );
}

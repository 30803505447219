import axios from 'axios';

import { Button } from '../../../components/Button';
import { AddChatItem } from '../AddChatItem';
import { responseItemFormat, createUUID } from '../utilities';
import { segmentStyle, segmentPassStyle } from '../chatItemStyle';
import { handleEdit, handleDeleteTag, handleAddTag } from '../editSegmentCard';

/**
 * セグメントカードをダイアログ形式に整形する関数
 * @function
 * @param {} segmentCard セグメントカードのデータ
 * @param {*} operation 行われた選択。add or pass
 * @returns {object}
 */
const segmentFormat = (segmentCard, operation) => {
  return {
    id: 'segment',
    category: 'segment',
    content: (
      <div
        className={operation === 'add' ? 'segment-card' : 'segment-card pass'}
        style={operation === 'add' ? segmentStyle : segmentPassStyle}
      >
        <div className="segment">
          <img src="images/person.svg" alt="" />
          {segmentCard.segment}
        </div>
        <div className="tag">
          {segmentCard.tags.map((tag, key) => (
            <div key={key} className="tag-wrap">
              <div className="tag-text">{tag}</div>
            </div>
          ))}
        </div>
      </div>
    ),
    nextAction: null,
    nextId: null,
  };
};

/**
 * セグメントカードと選択肢を表示するコンポーネント。選択後の処理も実行
 * @function
 * @param {*} chatItemList チャット欄表示ようリスト
 * @param {*} setChatItemList
 * @param {*} currentSegment 新規に提示したセグメントカードのオブジェクト
 * @param {*} originSegment 新規セグメントカードのオリジナル。useRef
 * @param {*} setCurrentSegment
 * @param {*} DMGenerateList DM作成するセグメントカードのリスト
 * @param {*} setDMGenerateList
 * @param {*} DMPassList DM作成しないセグメントカードのリスト
 * @param {*} setDMPassList
 * @param {*} setDMGenBtnIsActive
 * @param {*} isEditable 編集中かどうかの情報を持つ真偽値
 * @param {*} setIsEditable
 * @param {*} projectId DBから取得した現在のプロジェクトID
 * @returns {JSX.Element}
 */
export const SelectSegment = (
  chatItemList,
  setChatItemList,
  currentSegment,
  originSegment,
  setCurrentSegment,
  DMGenerateList,
  setDMGenerateList,
  DMPassList,
  setDMPassList,
  setDMGenBtnIsActive,
  isEditable,
  setIsEditable,
  projectId,
  setHandleAddGenList,
  segmentCount,
  setDMListOpen,
  lang,
  t
) => {
  /**
   * セグメントカードの情報をDBに登録する関数
   * @function
   * @param {*} segmentCard DBに登録するセグメントカードのオブジェクト
   */
  const segmentPOST = (segmentCard) => {
    axios
      .post('/pcb/api/v1/chat/segments/', {
        project_id: projectId,
        segment: segmentCard.segment,
        tags: segmentCard.tags,
        is_added: segmentCard.isAdded,
        is_passed: segmentCard.isPassed,
        is_edited: segmentCard.isEdited,
        same_root_uuid: segmentCard.sameRootUuid,
      })
      .then((res) => {
        console.log('segmentPOST done.');
        console.log(res);
        if (segmentCard.isAdded) {
          setDMGenerateList([
            ...DMGenerateList,
            {
              ...segmentCard,
              isGenerated: false,
              segmentId: res.data.id,
            },
          ]);
        } else if (segmentCard.isPassed) {
          setDMPassList([
            ...DMPassList,
            {
              ...segmentCard,
              isGenerated: false,
              segmentId: res.data.id,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log('segmentPOST error!');
        console.log(error);
      });
  };

  /**
   * 追加ボタン押下時の処理
   * @function
   * @returns {*}
   */
  const handleAdd = () => {
    // 初回のみリスト表示制御
    if (DMGenerateList.length === 0) {
      setDMListOpen(true);
      setDMGenBtnIsActive(true);
    }

    let newUuid = createUUID();
    const newSegment = {
      ...currentSegment,
      isAdded: true,
      sameRootUuid: newUuid,
    };
    setCurrentSegment(newSegment);

    // フォームの削除＋セグメントとレスポンスの追加
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();

    const DMListLength = DMGenerateList.length;

    // 提案回数が合計20件以下である場合
    if (segmentCount < 20) {
      // console.log(segmentCount);

      // 作成リスト初登録の場合
      if (DMListLength === 0) {
        AddChatItem(
          [
            segmentFormat(currentSegment, 'add'),
            responseItemFormat(
              t("segmentCard.add"),
              'dialogueAddedFirst',
              'Add',
            ),
          ],
          chatItemListTmp,
          setChatItemList,
        );

      // 初登録でない場合
      } else if (DMListLength < 7) {
        
        // カウントが5の倍数である場合
        if (segmentCount % 5 === 0) {
          console.log('reload');
          AddChatItem(
            [
              segmentFormat(currentSegment, 'add'),
              responseItemFormat(
                t("segmentCard.add"),
                'dialogueAddedReload',
                'Add',
              ),
            ],
            chatItemListTmp,
            setChatItemList,
          );

        // 5の倍数でない場合
        } else {
          AddChatItem(
            [
              segmentFormat(currentSegment, 'add'),
              responseItemFormat(
                t("segmentCard.add"),
                'dialogueAdded',
                'Add',
              ),
            ],
            chatItemListTmp,
            setChatItemList,
          );
        }

      // 8件目を登録した場合
      } else {
        AddChatItem(
          [
            segmentFormat(currentSegment, 'add'),
            responseItemFormat(
              t("segmentCard.add"),
              'dialogueAddedEnd',
              'Add',
            ),
          ],
          chatItemListTmp,
          setChatItemList,
        );
      }

    } else {
      AddChatItem(
        [
          segmentFormat(currentSegment, 'add'),
          responseItemFormat(
            t("segmentCard.add"),
            'dialogueAddedFull',
            'Add',
          ),
        ],
        chatItemListTmp,
        setChatItemList,
      );
    }

    // オリジンをDBに追加
    if (currentSegment.isEdited) {
      originSegment.sameRootUuid = newUuid;
      segmentPOST(originSegment);
    }
    segmentPOST(newSegment);
    setHandleAddGenList(prev => !prev);
  };

  /**
   * パスボタン押下時の処理
   * @function
   */
  const handlePass = () => {
    // 初回のみリスト表示制御
    if (DMGenerateList.length === 0 && DMPassList.length === 0) {
      setDMListOpen(false);
      setDMGenBtnIsActive(true);
    }

    originSegment.isPassed = true;
    let newUuid = createUUID();
    originSegment.sameRootUuid = newUuid;
    
    // フォームの削除＋セグメントとレスポンスの追加
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();

    // 提案回数が合計20件以下である場合
    if (segmentCount < 20) {
      // console.log(segmentCount);

      // カウントが5の倍数である場合
      if (segmentCount % 5 === 0) {
        console.log('reload');
        setChatItemList([
          ...chatItemListTmp,
          segmentFormat(originSegment, 'pass'),
          responseItemFormat(t("segmentCard.pass"), 'dialoguePassedReload', 'Pass'),
        ]);

      // 5の倍数でない場合
      } else {
        setChatItemList([
          ...chatItemListTmp,
          segmentFormat(originSegment, 'pass'),
          responseItemFormat(t("segmentCard.pass"), 'dialoguePassed', 'Pass'),
        ]);
      }
    } else {
      setChatItemList([
        ...chatItemListTmp,
        segmentFormat(originSegment, 'pass'),
        responseItemFormat(t("segmentCard.pass"), 'dialoguePassedFull', 'Pass'),
      ]);
    }
    segmentPOST(originSegment);
  };

  return (
    <>
      <div className="segment-card" style={segmentStyle}>
        <div className="segment">
          <img src="images/person.svg" alt="" />
          {isEditable ? (
            <input
              contentEditable
              value={currentSegment.segment}
              onChange={(e) =>
                setCurrentSegment({
                  ...currentSegment,
                  segment: e.target.value,
                })
              }
            />
          ) : (
            currentSegment.segment
          )}
        </div>

        <div className="tag">
          {isEditable ? (
            <>
              {currentSegment.tags.map((tag, key) => (
                <div key={key} className="tag-wrap active">
                  <input
                    className="tag-text"
                    contentEditable
                    value={tag}
                    onChange={(e) =>
                      setCurrentSegment({
                        ...currentSegment,
                        tags: currentSegment.tags.map((tag, i) =>
                          i === key ? e.target.value : tag,
                        ),
                      })
                    }
                  />
                  <div
                    className="tag-delete"
                    onClick={
                      () => handleDeleteTag(key, currentSegment, setCurrentSegment, chatItemList, setChatItemList, "atChat", lang)
                    }
                  >
                    <span></span>
                  </div>
                </div>
              ))}
              <div className="add-tag-wrap">
                <div
                  className={
                    currentSegment.tags.length >= 5
                      ? 'add-tag disabled'
                      : 'add-tag'
                  }
                  onClick={() => handleAddTag(currentSegment, setCurrentSegment, chatItemList, setChatItemList, "atChat", lang)}
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </>
          ) : (
            currentSegment.tags.map((tag, key) => (
              <div key={key} className="tag-wrap">
                <div className="tag-text">{tag}</div>
              </div>
            ))
          )}
        </div>
        <div className="edit-button" onClick={
          () => handleEdit(isEditable, setIsEditable, currentSegment, setCurrentSegment, "atChat", t, lang)
        }>
          {isEditable ? (
            <p>{t("segmentCard.update")}</p>
          ) : (
            <p>
              {t("segmentCard.edit")}
              <img src="images/pencil.svg" alt="" />
            </p>
          )}
        </div>
      </div>

      <div className="segment-button">
        <Button
          variant={isEditable ? 'disabled-fill-round' : 'secondary-fill-round'}
          className={isEditable ? 'disabled' : ''}
          onClick={() => handleAdd()}
        >
          {t("segmentCard.add")}
        </Button>
        <Button
          variant={isEditable ? 'disabled-line-round' : 'alert-line-round'}
          className={isEditable ? 'disabled' : ''}
          onClick={() => handlePass()}
        >
          {t("segmentCard.pass")}
        </Button>
      </div>
    </>
  );
};

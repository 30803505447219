import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Button } from '../../components/Button';
import { First, Second } from './Onboarding';
import { AddChatItem } from './AddChatItem';
import { InputBusiness } from './generateSegment/InputBusiness';
import { SelectCategory } from './generateSegment/SelectCategory';
import { SelectSegment } from './selectSegment/SelectSegment';
import { ReloadSegment } from './selectSegment/ReloadSegment';
import { CardList } from './generateDM/CardList';
import { GenerateIndicator } from './generateDM/GenerateIndicator';
import { tips } from './generateDM/tips';

import { chatItemParentStyle, chatItemStyle } from './chatItemStyle';
import { getDialogue, dbReadTags, createUUID, shuffleArray } from './utilities';

/**
 * チャット画面のコンポーネント
 * @function
 * @returns {JSX.Element}
 */
export const Chat = ({DMContentsList, setDMContentsList}) => {
  // ---------- 変数の初期化 ----------
  const [chatItemList, setChatItemList] = useState([]); // 画面表示用リスト
  const [businessSummary, setBusinessSummary] = useState(''); // ビジネス内容を格納する変数
  const [projectId, setProjectId] = useState(null); // プロジェクトID
  const [segmentTextList, setSegmentTextList] = useState([]); // GPTから取得したセグメントのリスト
  const [activeCategories, setActiveCategories] = useState([]); // 選択されたカテゴリのリスト
  const [currentSegment, setCurrentSegment] = useState({}); // 新しく作成したセグメントカード情報を格納するオブジェクト
  const originSegment = useRef({}); // 編集で更新されないオリジナルのセグメントカード
  const [segmentCount, setSegmentCount] = useState(0); // セグメントカードを提示した回数をカウントする変数
  const [isEditable, setIsEditable] = useState(false);
  const [DMGenerateList, setDMGenerateList] = useState([]); //DM作成リスト
  const [DMPassList, setDMPassList] = useState([]); //パスしたセグメントカードのリスト
  const [DMGenBtnIsActive, setDMGenBtnIsActive] = useState(false);
  const [uuid, setUuid] = useState();
  const [generateStatus, setGenerateStatus] = useState("waiting");

  const [DMListOpen, setDMListOpen] = useState(true);

  // const [DMContentsList, setDMContentsList] = useState([]);
  const [generateRate, setGenerateRate] = useState();
  const [handleAddGenList, setHandleAddGenList] = useState(false);

  const [categories, setCategories] = useState([]);

  const [tipsArray, setTipsArray] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();
  const [isActiveOnboarding, setIsActiveOnboarding] = useState(null);

  const scrollBottomRef = useRef(null);


  /**
   * スクロールを実行する関数
   * @function
   * @param {string} behavior スクロールの振る舞い
   * @returns {*} スクロールを実行する関数
   */
  const scrollToLatest = (behavior = 'smooth') =>
    scrollBottomRef.current.scrollIntoView({ behavior });

  const getTags = async (lang) => {
    const categoryList = await dbReadTags(lang);
    console.log(categoryList);
    setCategories(categoryList);
  }

  // 初回フック
  useEffect(() => {
    // オンボーディングの表示
    setIsActiveOnboarding("First");
    // チラシ生成データのリセット
    setDMContentsList([]);
    // uuidの取得
    setUuid(createUUID());
  }, []);

  const onboarding = () => {
    return (
      <div className={`onboarding modal-area ${(isActiveOnboarding === "First" || isActiveOnboarding === "Second") && "active"}`}>
        <div className='container'>
          {
            isActiveOnboarding === "First"
              ?
            <First setIsActiveOnboarding={setIsActiveOnboarding}></First>
              :
            isActiveOnboarding === "Second"
              ?
            <Second setIsActiveOnboarding={setIsActiveOnboarding}></Second>
              :
            <Second setIsActiveOnboarding={setIsActiveOnboarding}></Second>
          }
        </div>
        <div className='bg'></div>
      </div>
    )
  }

  // オンボーディングを閉じたら実行する
  useEffect(() => {
    if (isActiveOnboarding === "close") {
      // 最初のダイアログ
      AddChatItem([getDialogue('dialogue01', i18n.language)], chatItemList, setChatItemList);
      // 言語情報をセット
      setLang(i18n.language);
      // タグの取得
      getTags(i18n.language);
      // tipsのシャッフル
      const newTipsArray = shuffleArray(tips(i18n.language));
      setTipsArray(newTipsArray);
      console.log(newTipsArray);
    }
  }, [isActiveOnboarding]);

  /**
   * 副作用フック
   * 新しい会話データがchatItemListに追加されたら実行する
   */
  useEffect(() => {
    // console.log(chatItemList.slice(-1)[0] && chatItemList.slice(-1)[0].id);
    if (
      chatItemList.slice(-1)[0] &&
      (
        chatItemList.slice(-1)[0].nextAction === 'auto' ||
        chatItemList.slice(-1)[0].nextAction === 'delay'
      )
    ) {
      getNextDialogue(chatItemList.slice(-1)[0].nextAction);
    }

    scrollToLatest();
  }, [chatItemList]);


  const [abortController, setAbortController] = useState(new AbortController());
  /**
   * autoもしくはdelayで次のダイアログを追加する処理
   * @function
   */
  const getNextDialogue = async (action) => {
    abortController.abort(); // 前回の処理を中断

    // 新しいAbortControllerを作成
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    if (action === 'delay') {
      try {
        // 非同期処理
        await yourAsyncFunction(newAbortController.signal);
      } catch (err) {
        if (err.name === 'AbortError') {
          // 中断された場合の処理
          console.log('Skip dialogue');
        }
      }
    }

    const nextDialogue = getDialogue(chatItemList.slice(-1)[0].nextId, lang);

    // ---------- segment作成 ----------
    if (!nextDialogue) return;
    if (nextDialogue.category === 'formSegment') {
      originSegment.current = {
          segment: segmentTextList[segmentCount].segment,
          tags: segmentTextList[segmentCount].tags,
          type: segmentTextList[segmentCount].type,
          isAdded: false,
          isPassed: false,
          isEdited: false,
          sameRootUuid: '',
      }
      setCurrentSegment(originSegment.current);
      setSegmentCount(prev => prev + 1);

    }

    AddChatItem([nextDialogue], chatItemList, setChatItemList);
  }

  const yourAsyncFunction = (signal) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        resolve('処理が完了しました');
      }, 1000);
  
      // AbortControllerが中断されたらクリアする
      signal.addEventListener('abort', () => {
        clearTimeout(timeoutId);
        reject(new DOMException('アボートされました。', 'AbortError'));
      });
    });
  };

  useEffect(() => {
    // コンポーネントがアンマウントされたときにAbortControllerを中断する
    return () => abortController.abort();
  }, [abortController]);

  /**
   * ダイアログをクリックしたときに次のダイアログを追加する処理
   * @function
   */
  const handleClick = () => {
    getNextDialogue('click');
  };

  // 出力するコンテンツの制御
  /**
   * ダイアログを表示するコンポーネント
   * @function
   * @param {object} item ダイアログデータ
   * @returns {JSX.Element}
   */
  const chatItemContent = (item) => {
    // ビジネス内容をspan要素に挿入する処理
    if (item.category === 'dialogue' && item.id === 'dialogue03') {
      const parentType = item.content.type;
      const childrenArr = React.Children.toArray(item.content.props.children);
      const newChildren = childrenArr.map((child, key) => {
        if (child.props && child.props.className === 'business') {
          return React.createElement('span', (key = { key }), businessSummary);
        } else {
          return child;
        }
      });
      const newParent = React.createElement(parentType, null, [...newChildren]);
      return newParent;
      // ビジネス内容入力フォーム
    } else if (item.category === 'form') {
      return InputBusiness(
        chatItemList,
        setChatItemList,
        businessSummary,
        setBusinessSummary,
        t,
        lang
      );
      // カテゴリ選択フォーム
    } else if (item.category === 'formCategory') {
      return SelectCategory(
        chatItemList,
        setChatItemList,
        businessSummary,
        segmentTextList,
        setSegmentTextList,
        uuid,
        setProjectId,
        categories,
        setCategories,
        activeCategories,
        setActiveCategories,
        lang,
        t
      );
      // セグメントの提案と選択肢
    } else if (item.category === 'formSegment') {
      return SelectSegment(
        chatItemList,
        setChatItemList,
        currentSegment,
        originSegment.current,
        setCurrentSegment,
        DMGenerateList,
        setDMGenerateList,
        DMPassList,
        setDMPassList,
        setDMGenBtnIsActive,
        isEditable,
        setIsEditable,
        projectId,
        setHandleAddGenList,
        segmentCount,
        setDMListOpen,
        lang,
        t
      );
      // セグメントの再生成
    } else if (item.category === 'segmentReload') {
      return ReloadSegment(
        businessSummary,
        uuid,
        chatItemList,
        setChatItemList,
        segmentTextList,
        setSegmentTextList,
        setProjectId,
        activeCategories,
        setActiveCategories,
        lang
      );
      // DMインジケーター
    } else if (item.category === 'DMGenerating') {
      return <div></div>;
    } else {
      return item.content;
    }
  };

  /**
   * ボットアイコンを表示するコンポーネント
   * @param {string} category ダイアログのカテゴリ
   * @returns {JSX.Element}
   */
  const botIcon = (category) => {
    if (category === 'dialogue' || category === 'interval') {
      return (
        <div className="bot-icon">
          <img src="images/robot.svg" alt="" />
        </div>
      );
    }
  };

  return (
    <div className="chat h-100">
      {onboarding()}
      {chatItemList.map((chatItem, key) => {
        return (
          <div
            key={key}
            style={{
              ...chatItemParentStyle['common'],
              ...chatItemParentStyle[chatItem.category],
            }}
          >
            <div
              id={key}
              className={`chat-item ${chatItemList.slice(-1)[0].nextAction === 'delay' ? "dialogue" : ""}`}
              style={{
                ...chatItemStyle['common'],
                ...chatItemStyle[chatItem.category],
              }}
              onClick={
                chatItemList.slice(-1)[0].nextAction === 'delay' &&
                chatItem.id === chatItemList.slice(-1)[0].id
                  ? () => handleClick()
                  : null
              }
            >
              {botIcon(chatItem.category)}
              {chatItemContent(chatItem)}
            </div>
          </div>
        );
      })}
      <CardList
        businessSummary={businessSummary}
        chatItemList={chatItemList}
        setChatItemList={setChatItemList}
        DMGenerateList={DMGenerateList}
        setDMGenerateList={setDMGenerateList}
        DMPassList={DMPassList}
        setDMPassList={setDMPassList}
        DMGenBtnIsActive={DMGenBtnIsActive}
        setDMGenBtnIsActive={setDMGenBtnIsActive}
        setGenerateRate={setGenerateRate}
        setDMContentsList={setDMContentsList}
        handleAddGenList={handleAddGenList}
        setHandleAddGenList={setHandleAddGenList}
        setGenerateStatus={setGenerateStatus}
        DMListOpen={DMListOpen}
        setDMListOpen={setDMListOpen}
        lang={lang}
        t={t}
      />
      <GenerateIndicator
        generateRate={generateRate}
        DMGenerateList={DMGenerateList}
        projectId={projectId}
        businessSummary={businessSummary}
        DMContentsList={DMContentsList}
        generateStatus={generateStatus}
        tipsArray={tipsArray}
        t={t}
      />
      <div ref={scrollBottomRef} style={{ height: '100px' }}></div>
    </div>
    
  );

};
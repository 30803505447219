import { Button } from "../../components/Button";

export const dialogueContentData = (lang) => {
    switch (lang) {
        case "en":
            return (
                [
                  {
                    id: 'interval',
                    content: <p>......</p>,
                    nextAction: 'auto',
                    nextId: null,
                  },
                  {
                    id: 'dialogue01',
                    content: (
                      <p>
                        All flyer images are complete!
                        <br/>
                        Be sure to check out the personalized flyer images.
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                  {
                    id: 'contact01',
                    category: 'dialogue',
                    content: <p>Thank you for your interest in our services to the end!</p>,
                    nextAction: 'click',
                    nextId: 'contact02',
                  },
                  {
                    id: 'contact02',
                    category: 'dialogue',
                    content: (
                      <p>
                        We, the AccurioDX team, are engaged in 1-to-1 marketing through print media such as the one you have created here, and we co-create value with a variety of companies on a daily basis.
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: 'contact03',
                  },
                  {
                    id: 'contact03',
                    category: 'dialogue',
                    content: (<>
                      <p>
                        We have a program that allows anyone to start creating value through 1-to-1 paper marketing at low risk, so please feel free to contact us for more information!
                      </p>
                      <div
                        className="contact-button-container"
                        style={{ textAlign: 'center' }}
                      >
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open(
                              'https://accuriodx.konicaminolta.com/contact',
                              '_blank',
                            )
                          }
                        >
                          CONTACT
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open('https://accuriodx.konicaminolta.com/', '_blank')
                          }
                        >
                          Learn more about
                          <br />
                          AccurioDX
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                      </div>
                    </>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                ]
            )
        case "zh":
            return (
                [
                  {
                    id: 'interval',
                    content: <p>......</p>,
                    nextAction: 'auto',
                    nextId: null,
                  },
                  {
                    id: 'dialogue01',
                    content: (
                      <p>
                        所有传单图像均已完成！
                        <br />
                        请查看做成的个性化传单图像。
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                  {
                    id: 'contact01',
                    category: 'dialogue',
                    content: <p>感谢您的回复，并坚持到最后！</p>,
                    nextAction: 'click',
                    nextId: 'contact02',
                  },
                  {
                    id: 'contact02',
                    category: 'dialogue',
                    content: (
                      <p>
                        我们AccurioDX 团队，通过平面媒体进行 1 对 1 营销，并且每天都与不同的公司共同创造价值。
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: 'contact03',
                  },
                  {
                    id: 'contact03',
                    category: 'dialogue',
                    content: (<>
                      <p>
                        我们准备了任何人都能在低风险的情况下通过1to1纸营销创造价值的服务，期待您的咨询！
                      </p>
                      <div
                        className="contact-button-container"
                        style={{ textAlign: 'center' }}
                      >
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open(
                              'https://accuriodx.konicaminolta.com/contact',
                              '_blank',
                            )
                          }
                        >
                          咨询
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open('https://accuriodx.konicaminolta.com/', '_blank')
                          }
                        >
                          了解更多关于
                          <br />
                          AccurioDX的信息
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                      </div>
                    </>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                ]
            )
        case "ja":
        default:
            return (
                [
                  {
                    id: 'interval',
                    content: <p>......</p>,
                    nextAction: 'auto',
                    nextId: null,
                  },
                  {
                    id: 'dialogue01',
                    content: (
                      <p>
                        全てのチラシイメージが完成しました！
                        <br />
                        パーソナライズされたチラシイメージをぜひチェックしてみてください。
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                  {
                    id: 'contact01',
                    category: 'dialogue',
                    content: <p>最後までご利用いただきありがとうございます！</p>,
                    nextAction: 'click',
                    nextId: 'contact02',
                  },
                  {
                    id: 'contact02',
                    category: 'dialogue',
                    content: (
                      <p>
                        私たちAccurioDXチームでは、今回作成いただいたような紙媒体による1to1マーケティングを行い、日々さまざまな企業との価値共創を行っています。
                      </p>
                    ),
                    nextAction: 'click',
                    nextId: 'contact03',
                  },
                  {
                    id: 'contact03',
                    category: 'dialogue',
                    content: (<>
                      <p>
                        誰でも低リスクに1to1紙マーケティングによる価値創出を始められるプログラムをご用意しておりますので、ぜひお気軽にご相談ください！
                      </p>
                      <div
                        className="contact-button-container"
                        style={{ textAlign: 'center' }}
                      >
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open(
                              'https://accuriodx.konicaminolta.com/contact',
                              '_blank',
                            )
                          }
                        >
                          お問い合わせ
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                        <Button
                          variant="secondary-fill"
                          onClick={() =>
                            window.open('https://accuriodx.konicaminolta.com/', '_blank')
                          }
                        >
                          AcurrioDXについて
                          <br />
                          詳しく知る
                          <img src="images/new-tab.svg" alt="" className="new-tab" />
                        </Button>
                      </div>
                    </>
                    ),
                    nextAction: 'click',
                    nextId: null,
                  },
                ]
            )
}
  }
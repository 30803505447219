import { useTranslation } from "react-i18next";

/**
 * サムネイル用の表示を行うコンポーネント
 * @function
 * @param {*} DMContentsList 作成するDMコンテンツのリスト
 * @returns {JSX.Element}
 */
export const ThumbnailLayoutArea = ({ DMContentsList }) => {
  const { t, i18n } = useTranslation();

  // 各コンテンツの要素化
  const Salutation = ({ contents, style, className }) => (
    <p className={`salutation ${className}`} style={style}>
      {contents.salutation}
    </p>
  );
  const MainImage = ({ contents, style, className }) => (
    <img
      className={`main-image ${className}`}
      style={style}
      src={contents.mainImage}
      alt=""
    />
  );
  const StaffComment = ({ contents, style, className }) => (
    <p className={`staff-comment ${className}`} style={style}>
      {contents.staffComment}
    </p>
  );

  const Headline01 = ({ contents, style, className }) => (
    <p className={`headline ${className}`} style={style}>
      {contents.headline01}
    </p>
  );
  const Text01 = ({ contents, style, className }) => (
    <p className={`text ${className}`} style={style}>
      {contents.text01}
    </p>
  );
  const SectionImage01 = ({ contents, style, className }) => (
    <img
      className={`section-image ${className}`}
      style={style}
      src={contents.sectionImage01}
      alt=""
    />
  );
  const Headline02 = ({ contents, style, className }) => (
    <p className={`headline ${className}`} style={style}>
      {contents.headline02}
    </p>
  );
  const Text02 = ({ contents, style, className }) => (
    <p className={`text ${className}`} style={style}>
      {contents.text02}
    </p>
  );
  const SectionImage02 = ({ contents, style, className }) => (
    <img
      className={`section-image ${className}`}
      style={style}
      src={contents.sectionImage02}
      alt=""
    />
  );

  const CompanyName = ({ contents, style, className }) => (
    <p className={`company-name ${className}`} style={style}>
      {contents.companyName}
    </p>
  );
  const StaffName = ({ contents, style, className }) => (
    <p className={`staff-name ${className}`} style={style}>
      {contents.staffName}
    </p>
  );
  const StaffPost = ({ contents, style, className }) => (
    <p className={`staff-post ${className}`} style={style}>
      {contents.staffPost}
    </p>
  );
  const StaffPortraitImage = ({ contents, style, className }) => (
    <img
      className={`staff-portrait-image ${className}`}
      style={style}
      src={contents.staffPortraitImage}
      alt=""
    />
  );
  const CustomerName = ({ contents, style, className }) => (
    <p className={`customer-name ${className}`} style={style}>
      {contents.customerName}
    </p>
  );
  const CustomerQRCodeImage = ({ contents, style, className }) => (
    <img
      className={`customer-QRCode-image ${className}`}
      style={style}
      src={contents.customerQRCodeImage}
      alt=""
    />
  );

  // レイアウト用のコンテナ
  const DMContainer = ({ style, className, children }) => (
    <div className={`dm-container ${className}`} style={style}>
      {children}
    </div>
  );
  // 吹き出しを挿入するコンテナ
  const ArrowContainer = ({ style, className, children }) => (
    <div className={`arrow-container ${className}`} style={style}>
      {children}
    </div>
  );

  // 300px*444px
  return (
    <div className="capture-container" id="thumbnail">
      {DMContentsList.map((contents, key) => {
        return (
          <div key={key} id={'thumbnail' + key} className="d-flex">
            {/* ------------------------------- simple ------------------------------- */}
            <div
              className={'capture ' + contents.designTheme}
              style={
                contents.layout === 'simple'
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <DMContainer style={{ height: '25px' }} className="main-color">
                <CompanyName contents={contents} />
              </DMContainer>
              <DMContainer style={{ height: '100px' }}>
                <MainImage contents={contents} />
              </DMContainer>

              <DMContainer
                style={{ height: '50px' }}
                className="main-color paddingRL"
              >
                <CustomerName
                  contents={contents}
                  style={{ textAlign: 'left' }}
                />
                <Salutation contents={contents} style={{
                  fontSize:
                  ((i18n.language === "en" && contents.salutation.length > 36) || (i18n.language !== "en" && contents.salutation.length > 18))
                  && '0.6rem'
                }} />
              </DMContainer>

              <DMContainer
                style={{ height: '139px', flexDirection: 'row' }}
                className="base-color paddingRL paddingT"
              >
                <ArrowContainer style={{ width: '75%' }}>
                  <StaffComment
                    contents={contents}
                    style={{
                      textAlign: 'left',
                      fontSize:
                      ((i18n.language === "en" && contents.staffComment.length > 320) || (i18n.language !== "en" && contents.staffComment.length > 160))
                      && '0.4rem'
                    }}
                  />
                </ArrowContainer>

                <DMContainer
                  style={{
                    width: '25%',
                    height: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <StaffPortraitImage
                    contents={contents}
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '5px',
                    }}
                  />
                  <StaffPost contents={contents} />
                  <StaffName contents={contents} />
                </DMContainer>
              </DMContainer>

              <DMContainer
                style={{ height: '120px', flexDirection: 'row', gap: '4%' }}
                className="base-color paddingRL paddingT paddingB"
              >
                <DMContainer style={{ width: '76%', height: '100%' }}>
                  <DMContainer style={{}}>
                    <Headline01
                      contents={contents}
                      style={{
                        height: '20px',
                        lineHeight: '20px',
                        fontSize:
                        ((i18n.language === "en" && contents.headline01.length > 32) || (i18n.language !== "en" && contents.headline01.length > 16))
                        && '0.5rem'
                      }}

                      className="accent-color"
                    />
                  </DMContainer>

                  <DMContainer
                    style={{ height: '100px', flexDirection: 'row', gap: '2%' }}
                    className="base-color paddingT"
                  >
                    <SectionImage01
                      contents={contents}
                      style={{ width: '49%' }}
                    />
                    <DMContainer style={{ width: '49%', height: '100%' }}>
                      <Text01
                        contents={contents}
                        style={{
                          textAlign: 'left',
                          fontSize:
                          ((i18n.language === "en" && contents.text01.length > 140) || (i18n.language !== "en" && contents.text01.length > 72))
                          && '0.4rem'
                        }}
                      />
                    </DMContainer>
                  </DMContainer>
                </DMContainer>

                <DMContainer
                  style={{
                    width: '20%',
                    height: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <p className="accent-color" style={{ marginBottom: '5px' }}>
                    {t("other.contact1") + t("other.contact2")}↓
                  </p>
                  <CustomerQRCodeImage
                    contents={contents}
                    style={{ width: '45px', height: '45px' }}
                  />
                </DMContainer>
              </DMContainer>

              <DMContainer
                style={{ height: '10px' }}
                className="main-color"
              ></DMContainer>
            </div>

            {/* ------------------------------- informative ------------------------------- */}
            <div
              className={'capture ' + contents.designTheme}
              style={
                contents.layout === 'informative'
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <DMContainer
                style={{ height: '444px', padding: '5px' }}
                className="main-color"
              >
                <DMContainer
                  style={{ height: '35px' }}
                  className="base-color paddingRL"
                >
                  <CustomerName
                    contents={contents}
                    style={{ textAlign: 'left', fontSize: '0.6rem' }}
                  />
                  <Salutation
                    contents={contents}
                    style={{
                      lineHeight: '1rem',
                      fontSize:
                      ((i18n.language === "en" && contents.salutation.length > 36) || (i18n.language !== "en" && contents.salutation.length > 18))
                      ? '0.6rem' : '0.8rem'
                    }}

                  />
                </DMContainer>

                <DMContainer
                  style={{ height: '83px' }}
                  className="base-color paddingRL paddingT paddingB"
                >
                  <StaffComment
                    contents={contents}
                    style={{
                      textAlign: 'left',
                      fontSize:
                      ((i18n.language === "en" && contents.staffComment.length > 320) || (i18n.language !== "en" && contents.staffComment.length > 160))
                      ? '0.4rem' : '0.5rem'
                    }}
                  />
                </DMContainer>

                <DMContainer
                  style={{
                    height: '52px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                  }}
                  className="base-color paddingRL"
                >
                  <DMContainer style={{ width: '55%'}}>
                    <MainImage contents={contents} />
                  </DMContainer>

                  <DMContainer style={{ flexDirection: 'row', width: 'auto', alignItems: 'center' }}>
                    <DMContainer
                      style={{ flexDirection: 'column', padding: "0 5px" }}
                      className=""
                    >
                      <StaffPost
                        contents={contents}
                        style={{ width: 'auto' }}
                      />
                      <StaffName
                        contents={contents}
                        style={{ width: 'auto' }}
                      />
                    </DMContainer>
                    <StaffPortraitImage
                      contents={contents}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '0px',
                      }}
                    />
                  </DMContainer>
                </DMContainer>

                <DMContainer
                  style={{ height: '235px' }}
                  className="base-color paddingRL paddingT paddingB"
                >
                  <DMContainer
                    style={{
                      height: '225px',
                      borderRadius: '10px',
                      gap: '15px',
                    }}
                    className="main-color paddingRL paddingT paddingB"
                  >
                    <DMContainer
                      style={{ width: '100%', height: '50%', gap: '5px' }}
                    >
                      <DMContainer
                        style={{ width: '80%' }}
                        className="main-color paddingRL"
                      >
                        <Headline01
                          contents={contents}
                          style={{
                            height: '24px',
                            fontSize:
                            ((i18n.language === "en" && contents.headline01.length > 32) || (i18n.language !== "en" && contents.headline01.length > 16))
                            && '0.5rem'
                          }}
                        />
                        <div
                          className="bg-accent-color"
                          style={{
                            width: '15%',
                            height: '3px',
                            marginTop: '4px',
                          }}
                        ></div>
                      </DMContainer>

                      <DMContainer
                        style={{
                          height: '100px',
                          flexDirection: 'row',
                          gap: '2%',
                        }}
                      >
                        <SectionImage01
                          contents={contents}
                          style={{ width: '49%' }}
                        />
                        <DMContainer style={{ width: '49%', height: '100%' }}>
                          <Text01
                            contents={contents}
                            style={{
                              textAlign: 'left',
                              fontSize:
                                ((i18n.language === "en" && contents.text01.length > 140) || (i18n.language !== "en" && contents.text01.length > 72))
                                && '0.4rem'
                            }}
                          />
                        </DMContainer>
                      </DMContainer>
                    </DMContainer>

                    <DMContainer
                      style={{ width: '100%', height: '50%', gap: '5px' }}
                    >
                      <DMContainer
                        style={{ width: '80%' }}
                        className="main-color paddingRL"
                      >
                        <Headline02
                          contents={contents}
                          style={{
                            height: '24px',
                            fontSize:
                              ((i18n.language === "en" && contents.headline02.length > 32) || (i18n.language !== "en" && contents.headline02.length > 16))
                              && '0.5rem'
                          }}
                        />
                        <div
                          className="bg-accent-color"
                          style={{
                            width: '15%',
                            height: '3px',
                            marginTop: '4px',
                          }}
                        ></div>
                      </DMContainer>

                      <DMContainer
                        style={{
                          height: '100px',
                          flexDirection: 'row',
                          gap: '2%',
                        }}
                      >
                        <DMContainer style={{ width: '49%', height: '100%' }}>
                          <Text02
                            contents={contents}
                            style={{
                              textAlign: 'left',
                              fontSize:
                                ((i18n.language === "en" && contents.text02.length > 140) || (i18n.language !== "en" && contents.text02.length > 72))
                                && '0.4rem'
                              }}
                          />
                        </DMContainer>
                        <SectionImage02
                          contents={contents}
                          style={{ width: '49%' }}
                        />
                      </DMContainer>
                    </DMContainer>
                  </DMContainer>
                </DMContainer>

                <DMContainer
                  style={{
                    height: '29px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  className="base-color paddingRL PaddingB"
                >
                  <CompanyName
                    contents={contents}
                    style={{ textAlign: 'left', width: "60%" }}
                  />
                  <DMContainer style={{ flexDirection: 'row', width: "40%" }}>
                    <p
                      className="accent-color"
                      style={{ textAlign: 'right', marginRight: '5px' }}
                    >
                      {t("other.contact1")}
                      <br />
                      {t("other.contact2")}→
                    </p>
                    <CustomerQRCodeImage
                      contents={contents}
                      style={{ width: '25px', height: '25px' }}
                    />
                  </DMContainer>
                </DMContainer>
              </DMContainer>
            </div>

            {/* ------------------------------- dynamic ------------------------------- */}
            <div
              className={'capture ' + contents.designTheme}
              style={
                contents.layout === 'dynamic'
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <DMContainer
                style={{
                  height: '44px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                className="main-color paddingRL"
              >
                <CustomerName
                  contents={contents}
                  style={{ textAlign: 'left', height: '20px' }}
                />
                <Salutation
                  contents={contents}
                  style={{
                    lineHeight: i18n.language !== "en" && 1,
                    fontSize:
                      ((i18n.language === "en" && contents.salutation.length > 36) || (i18n.language !== "en" && contents.salutation.length > 18))
                      && '0.6rem'
                  }}
                />
              </DMContainer>

              <DMContainer style={{ height: '210px' }}>
                <MainImage contents={contents} />
              </DMContainer>

              <DMContainer
                style={{
                  height: '160px',
                  paddingTop: '90px',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  gap: '5px',
                }}
                className="base-color paddingRL"
              >
                <DMContainer
                  style={{ width: '40%', height: '100%', gap: '5px' }}
                >
                  <Headline01
                    contents={contents}
                    style={{
                      fontSize:
                        ((i18n.language === "en" && contents.headline01.length > 32) || (i18n.language !== "en" && contents.headline01.length > 16))
                        ? '0.3rem' : '0.4rem'
                    }}
                    className="accent-color"
                  />
                  <Text01
                    contents={contents}
                    style={{
                      textAlign: 'left',
                      fontSize:
                        ((i18n.language === "en" && contents.text01.length > 140) || (i18n.language !== "en" && contents.text01.length > 72))
                        ? '0.3rem' : '0.4rem'
                    }}
                  />
                </DMContainer>
                <SectionImage01
                  contents={contents}
                  style={{ width: '25%', height: '90%' }}
                />
              </DMContainer>

              <DMContainer style={{ height: '30px' }} className="main-color">
                <CompanyName contents={contents} />
              </DMContainer>

              {/* float */}
              <DMContainer
                style={{
                  width: '210px',
                  height: '210px',
                  position: 'absolute',
                  top: '28%',
                  right: '-15px',
                  borderRadius: '200px',
                  backgroundColor: '#ffffffcc',
                  padding: '10%',
                }}
              >
                <StaffComment
                  contents={contents}
                  style={{
                    maxHeight: '140px',
                    textAlign: 'left',
                    fontSize:
                      ((i18n.language === "en" && contents.staffComment.length > 320) || (i18n.language !== "en" && contents.staffComment.length > 160))
                      && '0.4rem'
                  }}
                />
              </DMContainer>

              <DMContainer
                style={{
                  width: '',
                  height: '',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  position: 'absolute',
                  bottom: '25%',
                  right: '10px',
                }}
              >
                <DMContainer
                  style={{
                    width: 'auto',
                    marginRight: '10px',
                    textAlign: 'left',
                  }}
                  className=""
                >
                  <StaffPost contents={contents} />
                  <StaffName contents={contents} />
                </DMContainer>
                <StaffPortraitImage
                  contents={contents}
                  style={{ width: '50px', height: '50px' }}
                />
              </DMContainer>

              <DMContainer
                style={{
                  width: '100px',
                  height: '100px',
                  position: 'absolute',
                  bottom: '18%',
                  left: '10px',
                  borderRadius: '100px',
                }}
                className="bg-accent-color"
              >
                <p style={{ marginBottom: '5px', color: 'white' }}>
                  {t("other.contact1")}
                  <br />
                  {t("other.contact2")}↓
                </p>
                <CustomerQRCodeImage
                  contents={contents}
                  style={{ width: '45px', height: '45px' }}
                />
              </DMContainer>
            </div>
          </div>
        );
      })}
    </div>
  );
};

import axios from 'axios';
import { toPng } from 'html-to-image';
import html2canvas from 'html2canvas';

import { GetGPTDMText } from './GetGPTDMText';
import { GetPixabayDMImage } from './GetPixabayDMImage';
import { AddChatItem } from '../AddChatItem';
import { sleep, getDialogue, shuffleArray } from '../utilities';
import { Button } from '../../../components/Button';

/**
 * DM作成タブを表示するコンポーネント
 * @function
 * @param {*} businessSummary ビジネス内容
 * @param {*} chatItemList チャット欄表示用のリスト
 * @param {*} setChatItemList
 * @param {*} DMGenerateList DM作成するセグメントのリスト
 * @param {*} setDMGenerateList
 * @param {*} DMPassList DM作成しないセグメントのリスト
 * @param {*} setDMPassList
 * @param {*} DMGenBtnIsActive DM作成ボタン押下を感知する真偽値
 * @param {*} setDMGenBtnIsActive
 * @returns {JSX.Element}
 */
export const GenerateDM = ({
  businessSummary,
  chatItemList,
  setChatItemList,
  DMGenerateList,
  setDMGenerateList,
  setDMGenBtnIsActive,
  setGenerateRate,
  setDMContentsList,
  setGenerateStatus,
  lang,
  t
}) => {
  /**
   * DM作成時に現在表示しているセグメントカードを削除する処理
   * @function
   * @returns {object[]} 削除済みのチャット欄表示用リスト
   */
  const chatInitialize = () => {
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();
    chatItemListTmp.pop();
    return chatItemListTmp;
  };

  /**
   * DM作成時のチャット欄表示を挿入する処理
   * @function
   * @param {*} chatInitialized チャットリスト
   */
  const chatUpdateBeforeGenerate = async (chatInitialized) => {
    await AddChatItem(
      [getDialogue('dialogue06', lang)],
      chatInitialized,
      setChatItemList,
    );
  };

  /**
   * GPT APIからの取得を待機する関数
   * @function
   * @param {number} i 作成するDMの順番
   * @returns {object} GPT取得結果のオブジェクト
   */
  const callGptAPI = async (i) => {
    // console.log('----------GPT取得開始----------');
    const GPTContents = await GetGPTDMText(businessSummary, DMGenerateList[i], lang);
    // console.log(GPTContents);
    // console.log('----------GPT取得終了----------');
    return GPTContents;
  };

  /**
   * Pixabay APIからの取得を待機する関数
   * @function
   * @param {object} GPTContents GPT取得結果
   * @param {number} i 作成するDMの順番
   * @returns {object} Pixabay取得結果のオブジェクト
   */
  const callPixabayAPI = async (GPTContents, i) => {
    // console.log('----------Pixabay取得開始----------');
    const PixabayContents = await GetPixabayDMImage(
      businessSummary,
      GPTContents.headline01,
      GPTContents.headline02,
      lang
    );
    // console.log(PixabayContents);
    // console.log('----------Pixabay取得終了----------');
    return PixabayContents;
  };

  /**
   * 取得したデータを統合する関数
   * @function
   * @param {*} GPTContents GPT取得結果
   * @param {*} pixabayContents Pixabay取得結果
   * @param {number} i DMの順番
   * @returns {object} 取得結果を統合したオブジェクト
   */
  const concatDMContents = async (GPTContents, pixabayContents, newIndividualList, newGroupList, i) => {
    const type = DMGenerateList[i].type;
    const staffNum = Math.floor(Math.random() * 2);
    let stakeholders = {};
    switch (lang) {
      case "en":
        const staffEn = [
          {name: "Johnson Smith", post: "Senior Sales Manager", image: "images/sample/portrait_sample01.png"},
          {name: "Emily Wilson", post: "Sales Department", image: "images/sample/portrait_sample02.png"}
        ]
        stakeholders = {
          companyName: 'Coco Simulator Corp.',
          staffName: staffEn[staffNum].name,
          staffPost: staffEn[staffNum].post,
          staffPortraitImage: staffEn[staffNum].image,
          customerName: type === "individual" ? "Dear " + newIndividualList[i] : "Dear " + newGroupList[i],
          customerQRCodeImage: 'images/sample/QR_code_sample.png',
          listSequence: i,
        };
        break;
      case "zh":
        const staffZh = [
          {name: "王伟", post: "营业部长", image: "images/sample/portrait_sample01.png"},
          {name: "张美丽", post: "营业部", image: "images/sample/portrait_sample02.png"}
        ]
        stakeholders = {
          companyName: '可可模拟器有限公司',
          staffName: staffZh[staffNum].name,
          staffPost: staffZh[staffNum].post,
          staffPortraitImage: staffZh[staffNum].image,
          customerName: type === "个人" ? "亲爱的 " + newIndividualList[i] + ":" : "亲爱的 " + newGroupList[i] + ":",
          customerQRCodeImage: 'images/sample/QR_code_sample.png',
          listSequence: i,
        };
        break;
      case "ja":
      default:
        const staffJa = [
          {name: "松下耕作", post: "営業部長", image: "images/sample/portrait_sample01.png"},
          {name: "木下麻貴", post: "営業部", image: "images/sample/portrait_sample02.png"}
        ]
        stakeholders = {
          companyName: 'ココシミュレーター株式会社',
          staffName: staffJa[staffNum].name,
          staffPost: staffJa[staffNum].post,
          staffPortraitImage: staffJa[staffNum].image,
          customerName: type === "個人" ? newIndividualList[i] + " 様" : newGroupList[i] + " 御中",
          customerQRCodeImage: 'images/sample/QR_code_sample.png',
          listSequence: i,
        };
        break;
    }
    return {
      ...GPTContents,
      ...pixabayContents,
      ...stakeholders,
      segmentId: DMGenerateList[i].segmentId,
    };
  };

  /**
   * DMのコンテンツデータをレンダリングする関数
   * @function
   * @param {*} DMContents 統合されたDMコンテンツデータ
   */
  const renderThumbnail = (DMContents) => {
    setDMContentsList((prevList) => [...prevList, DMContents]);
  };

  /**
   * レンダリングされるまで待機する関数
   * @function
   * @param {*} num 作成するDMの順番
   * @returns {*} レンダリングされたDMの要素
   */
  const getNode = async (num) => {
    let i = 0;
    while (!document.getElementById('thumbnail' + num)) {
      await sleep(200);
      i++;
      if (i === 10) {
        break;
      }
    }
    return document.getElementById('thumbnail' + num);
  };

  /**
   * レンダリングされたDMの要素をPNGファイル化する関数
   * @function
   * @param {*} i
   * @returns PNGデータ
   */
  const genThumbnail = async (i) => {
    // console.log('----------サムネイル作成開始----------');
    const node = await getNode(i);
    // console.log(node);

    // const thumbData2 = await toPng(node, {
    //   canvasWidth: 600 / window.devicePixelRatio,
    //   canvasHeight: 888 / window.devicePixelRatio,
    //   // method: 'GET',
    //   // mode: 'cors',
    //   // cache: 'no-cache',
    //   // credentials: 'same-origin',
    //   // headers: { 'Content-Type': 'application/json', },
    // });
    // console.log(thumbData2);

    let thumbData = null;
    await html2canvas(node, {
      proxy: true,
      useCORS: true,
      scale: 2,
    }).then((canvas) => {
      thumbData = canvas.toDataURL("image/png");
    });
    let j = 0;
    while (!thumbData) {
      await sleep(200);
      j++;
      if (j === 10) {
        break;
      }
    }

    // console.log('----------サムネイル作成終了----------');
    return thumbData;
  };

  /**
   * サムネイル画像をサーバーに保存する関数
   * @function
   * @param {*} thumbData サムネイルのpngデータ
   * @param {*} DMContents DMコンテンツデータ
   */
  const saveThumbnail = async (thumbData, DMContents) => {
    await axios
      .post('/pcb/api/v1/chat/thumbnail/', { url: thumbData })
      .then((res) => {
        console.log('saveThumbnail done.');
        console.log(res);
        DMContents.thumbnailImage = res.data.fileName;
      })
      .catch((error) => {
        console.log('saveThumbnail error!');
        console.log(error);
      });
  };

  /**
   * DMコンテンツデータをDBに保存する関数
   * @function
   * @param {*} DMContents DMコンテンツデータ
   */
  const postDMContents = (DMContents) => {
    axios
      .post('/pcb/api/v1/chat/DM/', {
        staff_portrait_image: DMContents.staffPortraitImage,
        customer_qr_code_image: DMContents.customerQRCodeImage,
        thumbnail_image: DMContents.thumbnailImage,
        main_image: DMContents.mainImage,
        section_image01: DMContents.sectionImage01,
        section_image02: DMContents.sectionImage02,

        company_name: DMContents.companyName,
        staff_name: DMContents.staffName,
        staff_post: DMContents.staffPost,
        customer_name: DMContents.customerName,

        segment_id: DMContents.segmentId,
        list_sequence: DMContents.listSequence,
        design_theme: DMContents.designTheme,
        layout: DMContents.layout,

        salutation: DMContents.salutation,
        staff_comment: DMContents.staffComment,

        headline01: DMContents.headline01,
        headline02: DMContents.headline02,
        text01: DMContents.text01,
        text02: DMContents.text02,
      })
      .then((res) => {
        console.log('postDMContents done.');
        console.log(res);
      })
      .catch((error) => {
        console.log('postDMContents error!');
        console.log(error);
      });
  };

  /**
   * インジケータを更新する関数
   * @function
   * @param {*} DMContents
   * @param {*} chatInitialized DM作成前のチャットリスト
   * @param {*} i
   */
  const updateIndicator = (DMContents, chatInitialized, i) => {
    const DMGenerateListTmp = DMGenerateList;
    DMGenerateListTmp[i].isGenerated = true;
    DMGenerateListTmp[i].thumbnailImage = DMContents.thumbnailImage;
    setDMGenerateList(DMGenerateListTmp);
  };

  /**
   * DM作成ボタン押下時に走るDM作成の一連処理
   * @function
   */
  const handleDMGenerate = async () => {
    if (DMGenerateList.length === 0) return;
    console.log('----------チラシイメージ作成開始----------');
    setGenerateStatus("working");
    setDMGenBtnIsActive(false); //DM作成リストを隠す
    const chatInitialized = chatInitialize();
    await chatUpdateBeforeGenerate(chatInitialized);

    let groupList = [];
    let individualList = [];
    switch (lang) {
      case "en":
        groupList = [
          "Innovate Technics Corp.",
          "Wellness Consultings LLC",
          "Startup Home LLC",
          "Partners Happiness Corp.",
          "Futurisk Corp.",
          "NeoVisionWay Corp.",
          "FlowerWind Enterprises LLC",
          "Prosperos LLC",
        ];
        individualList = [
          "Brian Johnson",
          "Sarah Thompson",
          "Michael Roberts",
          "Amanda Brown",
          "Kevin Smith",
          "Jessica Taylor",
          "David Jones",
          "Lauren Williams"
        ];
        break;
      case "zh":
        groupList = [
          "创新技术有限公司",
          "尼欧健康顾问有限公司",
          "创业之家合伙公司",
          "伙伴幸福有限公司",
          "未来风险有限公司",
          "视野之路有限公司",
          "花风企划有限公司",
          "繁荣之门合伙公司",
        ];
        individualList = [
          "李强",
          "王丽",
          "张伟",
          "刘芳",
          "陈娟",
          "杨勇",
          "黄萍",
          "徐军"
        ];
        break;
      case "ja":
      default:
        groupList = [
          "株式会社 イノベーションテクニクス",
          "有限会社 ウェルネスコンサルティング",
          "合同会社 創業舎",
          "株式会社 パートナーズハピネス",
          "株式会社 フューチャリスク",
          "株式会社 ビジョンウェイ",
          "有限会社 花風企画",
          "合同会社 プロスペロス",
        ];
        individualList = [
          "田中裕介",
          "山本みさき",
          "小林太郎",
          "鈴木あかり",
          "佐藤健太",
          "中村あやか",
          "佐々木こうじ",
          "渡辺みさと"
        ];
        break;
    }
    // プリセットデータの準備
    const newIndividualList = shuffleArray(individualList);
    const newGroupList = shuffleArray(groupList);

    // ---------- DM作成リストをループ処理 ----------
    for (let i = 0; i < DMGenerateList.length; i++) {
      // console.log(`----------${i + 1}枚目開始----------`);
      setGenerateRate(0);
      const GPTContents = await callGptAPI(i);
      setGenerateRate(1);
      const pixabayContents = await callPixabayAPI(GPTContents, i);
      setGenerateRate(2);
      const DMContents = await concatDMContents(
        GPTContents,
        pixabayContents,
        newIndividualList,
        newGroupList,
        i,
      );
      // console.log(DMContents);
      renderThumbnail(DMContents);
      const thumbData = await genThumbnail(i);
      await saveThumbnail(thumbData, DMContents);
      postDMContents(DMContents);
      setGenerateRate(3);
      updateIndicator(DMContents, chatInitialized, i);
      // console.log(`----------${i + 1}枚目終了----------`);
    }
    console.log('----------チラシイメージ作成終了----------');
    setGenerateStatus("completed");
  };

  return (
    <>
      <Button
        variant={DMGenerateList.length > 0 ? "accent-fill" : "disabled-fill"}
        onClick={() => handleDMGenerate()}>
        {t("cardList.generate")}
      </Button>
    </>
  );
};

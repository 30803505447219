import { beforeDialogueFormat, sleep } from './utilities';

/**
 * ダイアログデータをチャット欄に追加する関数
 * @function
 * @param {object[]} newItemList 追加するダイアログの配列(基本は1つ)
 * @param {object[]} chatItemList チャット欄に表示しているダイアログのリスト
 * @param {*} setChatItemList
 */
export const AddChatItem = async (
  newItemList,
  chatItemList,
  setChatItemList,
) => {
  const chatItemListTmp = chatItemList;

  // ダイアログの場合ローディングを挟む
  if (newItemList[0].category === 'dialogue') {
    setChatItemList([...chatItemListTmp, beforeDialogueFormat()]);
    // ローディング時間
    await sleep(1000);
    setChatItemList([...chatItemListTmp, ...newItemList]);
  } else {
    setChatItemList([...chatItemListTmp, ...newItemList]);
  }
};

import { getDialogue } from "./utilities";

/**
 * 編集ボタン押下時の処理
 * @function
 */
export const handleEdit = (isEditable, setIsEditable, currentSegment, setCurrentSegment, at, t, lang) => {
    // バリデーション
    if (isEditable) {
        let alertText = '';
        if (currentSegment.segment.length === 0) {
        alertText = alertText.concat(t("segmentCard.emptySegment"));
        } else if (currentSegment.segment.length > (lang === "en" ? 40 : 16)) {
        alertText = alertText.concat(t("segmentCard.fullSegment"));
        }
        if (currentSegment.tags.some((tag) => tag.length > (lang === "en" ? 40 : 16))) {
        alertText = alertText.concat(t("segmentCard.fullTag"));
        }
        if (alertText) {
        alert(alertText);
        return;
        }
    }
    let withoutNullTags = [];
    currentSegment.tags.forEach((tag) =>
        tag ? withoutNullTags.push(tag) : null,
    );

    setIsEditable((prev) => !prev);
    setCurrentSegment({
        ...currentSegment,
        isEdited: true,
        tags: withoutNullTags,
    });
};

/**
 * タグ削除ボタン押下時の処理
 * @param {number} key 削除するタグの順番
 */
export const handleDeleteTag = (key, currentSegment, setCurrentSegment, chatItemList, setChatItemList, at, lang) => {
    let currentSegmentTmp = currentSegment;
    currentSegmentTmp.tags.splice(key, 1);
    setCurrentSegment(currentSegmentTmp);

    // チャットリストを再レンダリングする処理
    if (at === "atChat") {
        let chatItemListTmp = chatItemList;
        chatItemListTmp.pop();
        setChatItemList([...chatItemList, getDialogue('formSegment', lang)]);
    }
};

/**
 * タグ追加ボタン押下時の処理
 */
export const handleAddTag = (currentSegment, setCurrentSegment, chatItemList, setChatItemList, at, lang) => {
    let currentSegmentTmp = currentSegment;
    currentSegmentTmp.tags.push('');
    setCurrentSegment(currentSegmentTmp);

    // チャットリストを再レンダリングする処理
    if (at === "atChat") {
        let chatItemListTmp = chatItemList;
        chatItemListTmp.pop();
        setChatItemList([...chatItemList, getDialogue('formSegment', lang)]);
    }
};
import axios from 'axios';

/**
 * GPT APIにDMのテキストコンテンツ生成をリクエストする関数
 * @function
 * @param {*} businessSummary ぶじねす内容
 * @param {*} card セグメントカード
 * @returns {*} 生成したテキストのオブジェクト
 */
export const GetGPTDMText = (businessSummary, card, lang) => {
  const apiKey = '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';

  let responses = {
    salutation: '',
    staffComment: '',
    headline01: '',
    text01: '',
    headline02: '',
    text02: '',
    designTheme: '',
    layout: '',
  };
  let questionNum = 1;

  const promptJa = [
    `#命令書:
    あなたは自社のビジネスを売り込む優秀なマーケターです。
    ビジネス・顧客情報を踏まえて下記の制約条件を守り、顧客に訴求するDMの見出しとメッセージを考えてください。
    
    #ビジネス:
    ${businessSummary}
    
    #顧客情報:
    ・セグメント:${card.segment}
    ・顧客の特徴:${card.tags.join(', ')}
    
    #見出しの制約条件:
    ・内容:DM送付の目的を示すコピー
    ・例:「〜のご案内」「〜しませんか？」「ご購入ありがとうございました」
    ・18文字以下
    
    #メッセージの制約条件:
    ・概要:自社スタッフからのメッセージ
    ・基本構成:挨拶文、顧客のニーズに言及する文章、ニーズを満たす自社の取り組みに関する文章、「ぜひ〜ください」など締めの文章、以上の４文で書く
    ・130文字以上160文字以下
    ・改行なし
    
    #出力文(json形式):
    {"salutation": "<見出し>", "staffComment": "<メッセージ>"}
    `,
    `
    #命令書:
    あなたは自社のビジネスを売り込む優秀なマーケターです。
    ビジネス・顧客情報を踏まえて下記の制約条件を守り、顧客に訴求するDMのサブコンテンツを2つ考えてください。
    
    #ビジネス:
    ${businessSummary}
    
    #顧客情報:
    ・セグメント:${card.segment}
    ・顧客の特徴:${card.tags.join(', ')}
    
    #制約条件:
    ・サブコンテンツは見出しと本文で構成
    ・見出しの文字数:16文字以下
    ・本文の文字数:55文字以上72文字以下
    ・文字数は絶対に超えてはいけない
    ・顧客に訴求するトピック(自社のキャンペーンやお知らせ、新商品の紹介などを具体的に)
    ・開始と締めの挨拶は不要
    ・改行なし
    
    #出力文(json):
    [
    {"headline": "<見出し1>", "text": "<本文1>"},
    {"headline": "<見出し2>", "text": "<本文2>"}
    ]
    `,
    `
    #命令書:
    あなたは自社のビジネスを売り込む優秀なマーケターです。
    ビジネス・顧客情報を踏まえて下記の制約条件を守り、顧客に訴求するDMのデザインテーマとレイアウトとして適切なものを1つずつ選択してください。
    
    #ビジネス:
    ${businessSummary}
    
    #顧客情報:
    ・セグメント:${card.segment}
    ・顧客の特徴:${card.tags.join(', ')}
    
    #制約条件:
    ・デザインテーマの選択肢:[formal, active, natural, luxury, delicious, girly, modern, japanese]
    ・レイアウトの選択肢:[simple, dynamic, informative]
    ・選択が難しい場合はランダム選択でよい
    ・レイアウトの選択は特にランダムでよい
    
    #出力文(json):
    {"designTheme": "<デザインテーマ>", "layout": "<レイアウト>"}`,
    ];
    
    const promptEn = [
    `
    #Instructions:
    You are a talented marketer selling your business.
    Based on your business and customer information, adhere to the constraints below and come up with a DM headline and message that will appeal to your customers.
    
    #Business:
    ${businessSummary}
    
    #Customer Information:
    ・Segment:${card.segment}
    ・Customer Characteristics:${card.tags.join(', ')}
    
    #Headline Constraints:
    ・Content: Copy that indicates the purpose of sending the DM.
    ・Example: "Information on ~" "Would you like to ~? Thank you for your purchase.
    ・Less than 36 characters  
    
    #Message constraints:
    ・Outline: Message from our staff
    ・Basic structure: A greeting, a sentence referring to the customer's needs, a sentence describing the company's efforts to meet those needs, and a closing sentence such as "Please take a moment to read this.
    ・Between 260 and 320 characters
    ・No line breaks
    
    
    #Output statement (json):
    {"salutation": "<Headline>", "staffComment": "<Message>"}
    `,
    `
    #Instructions:
    You are an excellent marketer who is selling your business.
    Based on your business and customer information, please come up with two DM sub-content that will appeal to your customers while adhering to the following constraints.
    
    #Business:
    ${businessSummary}
    
    #Customer Information:
    ・Segment:${card.segment}
    ・Customer Characteristics:${card.tags.join(', ')}
    
    #Constraints:
    ・Sub content should consist of headings and body text.
    ・Heading: 32 characters or less
    ・Body: Between 110 and 140 characters
    ・The number of characters must never be exceeded.
    ・Topics that appeal to customers (e.g., your company's campaigns, announcements, new product introductions, etc.)
    ・No opening and closing greetings
    ・No line breaks
    
    #Output statement (json):
    [
    {"headline": "<Heading1>", "text": "<Body1>"},
    {"headline": "<Heading2>", "text": "<Body2>"}
    ]
    `,
    `
    #Instructions:
    You are a talented marketer who is selling your business.
    Based on your business and customer information, choose one appropriate design theme and layout for each DM that appeals to your customers while adhering to the following constraints.
    
    #Business:
    ${businessSummary}
    
    #Customer Information:
    ・Segment:${card.segment}
    ・Customer Characteristics:${card.tags.join(', ')}
    
    #Constraints:
    ・Design theme options:[formal, active, natural, luxury, delicious, girly, modern, japanese]
    ・Layout options:[simple, dynamic, informative]
    ・If it is difficult to choose, random selection is acceptable.
    ・Layout choices can be random in particular.
    
    #Output statement (json):
    {"designTheme": "<design theme>", "layout": "<layout>"}`,
    ];
    
    const promptZh = [
    `
    #命令书：
    你是推销自己公司业务的优秀营销人员。
    请根据商务和顾客信息，遵循以下限制条件，考虑吸引客户的两个DM子内容。
    
    #商业信息：
    ${businessSummary}
    
    #客户信息：
    ・段:${card.segment}
    ・顾客的特征:${card.tags.join(', ')}
    
    #标题限制：
    ・内容：表示DM发送目的的复制
    ・例:“介绍……”“要……吗?”“感谢您的购买。”
    ・18个字以下
    
    #消息限制：
    ・概要：来自本公司员工的信息
    ・基本构成：寒暄语、提及顾客需求的文章、关于满足需求的本公司的搭配的文章、“请务必~”等结尾的文章、以上4句
    ・130字以上160字以下
    ・无换行
    
    #输出语句（json格式）：
    {"salutation": "<标题>", "staffComment": "<消息>"}
    `,
    `
    #命令书：
    你是推销自己公司业务的优秀营销人员。
    请根据商务和顾客信息，遵循以下限制条件，思考能吸引顾客的DM标题和信息。
    
    #商业信息：
    ${businessSummary}
    
    #客户信息：
    ・段:${card.segment}
    ・顾客的特征:${card.tags.join(', ')}
    
    #约束条件:
    ・副内容包括标题和正文。
    ・标题字符长度：16 个字符或更少。
    ・正文长度必须在 55 至 72 个字符之间。
    ・绝不能超过字符数。
    ・吸引客户的主题（如有关公司活动、公告、新产品等的具体信息）。
    ・无开头和结尾问候语。
    ・无换行符
    
    #输出语句（json格式）：
    [
    {"headline": "<标题1>", "text": "<正文1>"},
    {"headline": "<标题2>", "text": "<正文2>"}
    ]
    `,
    `
    #命令书：
    你是推销自己公司业务的优秀营销人员。
    根据业务客户信息，请遵守以下限制条件，为客户选择合适的DM设计主题和布局。
    
    #商业信息：
    ${businessSummary}
    
    #客户信息：
    ・段:${card.segment}
    ・顾客的特征:${card.tags.join(', ')}
    
    #约束条件：
    ・设计主题的选择：[formal, active, natural, luxury, delicious, girly, modern, japanese]
    ・布局选项：[simple, dynamic, informative]
    ・选择困难时可以随机选择
    ·布局选择可以是特别随机的
    
    
    #输出语句（json格式）：
    {"designTheme": "<デザインテーマ>", "layout": "<レイアウト>"}`,
    ];

    let prompt = [];
    switch (lang) {
      case "en":
        prompt = promptEn;
        break;
      case "zh":
        prompt = promptZh;
        break;
      case "ja":
      default:
        prompt = promptJa;
        break;
    }
    // console.log(prompt);

  return (async () => {
    for (const question of prompt) {
      try {
        // テスト
        // throw "error";
        const res = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            "model": "gpt-4",
            messages: [{ role: 'user', content: question }],
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${apiKey}`,
            },
          },
        );
        console.log(res.data.choices[0].message);
        const parsedRes = JSON.parse(res.data.choices[0].message.content);

        if (questionNum === 1) {
          responses.salutation = parsedRes.salutation;
          responses.staffComment = parsedRes.staffComment;
        } else if (questionNum === 2) {
          responses.headline01 = parsedRes[0].headline;
          responses.text01 = parsedRes[0].text;
          responses.headline02 = parsedRes[1].headline;
          responses.text02 = parsedRes[1].text;
        } else if (questionNum === 3) {
          const randomNum = Math.floor(Math.random() * 2) + 1;
          responses.designTheme = parsedRes.designTheme + randomNum;
          responses.layout = parsedRes.layout;
        }
      } catch (error) {
        console.log(
          'QUESTION' + questionNum + 'について正しく取得できませんでした。',
        );
        console.log(error);
        if (questionNum === 1) {
          if (lang === "en") {
            responses.salutation = 'Thank you for your patronage!';
            responses.staffComment = 'Thank you very much. Your purchase is a great honor for us. We sincerely hope that our products will be of service to you. If you have any questions or concerns, please feel free to let us know at any time. All of us will continue to make every effort to provide you with satisfactory service.';
          } else if (lang === "zh") {
            responses.salutation = '感谢您的惠顾';
            responses.staffComment = '非常感谢。 您的购买是我们的荣幸。 我们衷心希望我们的产品对您有用。 如果您有任何问题或疑问，请随时告诉我们。 我们全体员工将继续努力，为您提供满意的服务。 我们期待与您合作。';
          } else {
            responses.salutation = 'ご購入ありがとうございます';
            responses.staffComment = '誠にありがとうございます。お客様のご購入は、弊社にとって大変光栄です。商品がお役に立てますよう、心より願っております。何かご不明点やご質問がございましたら、いつでもお気軽にお知らせください。今後もお客様に満足いただけるサービスを提供できるよう、全スタッフ一同努めて参ります。どうぞよろしくお願いいたします。';
          }
        } else if (questionNum === 2) {
          if (lang === "en") {
            responses.headline01 = 'Campaign Information';
            responses.text01 = 'Enjoy shopping with exclusive products and great discounts. We also offer special prices and gifts for a limited time only.';
            responses.headline02 = 'New Products';
            responses.text02 = 'The latest items are now available. We also offer special prices and limited edition sets. Why don\'t you make your daily life more special with our new items?';
          } else if (lang === "zh") {
            responses.headline01 = '活动通知';
            responses.text01 = '请享受限定商品和实惠的折扣购物。为您准备了期间限定的特别价格和礼物。在购物的同时可以获得有趣的中奖机会。请一定要利用这个机会。详情请访问网站。';
            responses.headline02 = '新商品介绍';
            responses.text02 = '已经把最新的项目加入到队列中。同时为您准备了优惠的特别价格和限定套餐。新的物品会让日常生活更加特别吗？请务必借此机会尝试新商品。详细情况请看网站。';
          } else {
            responses.headline01 = 'キャンペーンのお知らせ';
            responses.text01 = '限定商品やお得な割引でお買い物をお楽しみください。期間限定の特別価格やプレゼントもご用意しております。お買い物がさらに楽しくなるチャンスです。';
            responses.headline02 = '新商品のご案内';
            responses.text02 = '最新アイテムが仲間入りしました。お得な特別価格や限定セットもご用意しております。新しいアイテムで日常をより特別なものにしませんか？';
          }
        } else if (questionNum === 3) {
          const randomNum = Math.floor(Math.random() * 3);
          const layoutArr = ["simple", "dynamic", "informative"];
          responses.designTheme = 'formal1';
          responses.layout = layoutArr[randomNum];
        }
      } finally {
        questionNum++;
      }
    }
    return responses;
  })();
};
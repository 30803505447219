import { useNavigate } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';

import { Button } from './Button';

import { useTranslation } from "react-i18next";

/**
 * ヘッダーのコンポーネント
 * @function
 * @returns {JSX.Element}
 */
export const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToTopPage = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <>
      <header>
        <Navbar>
          <Container className="konimino-nav">
            <Navbar.Brand href="https://www.konicaminolta.com/" target="_blank">
              <img src="/images/identity01g.svg" alt="" />
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Navbar>
          <Container className="accurio-nav">
            <Navbar.Brand href="https://accuriodx.konicaminolta.com/">
              <img src="/images/original.svg" alt="" />
            </Navbar.Brand>

            <Navbar.Collapse className="justify-content-end gap-3">
              <Button
                variant="primary-line"
                onClick={() =>
                  window.open(
                    'https://accuriodx.konicaminolta.com/contact',
                    '_blank',
                  )
                }
              >
                <span className="new-tab">
                  {t("header.contact")}
                  <img src="images/new-tab.svg" alt="" className="new-tab" />
                </span>
              </Button>
              <Button variant="primary-fill" onClick={() => goToTopPage()}>
                {t("header.top")}
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="header-bar"></div>
      </header>
    </>
  );
};

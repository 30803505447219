import { Button } from '../../../components/Button';
import { responseItemFormat } from '../utilities';

/**
 * ビジネス内容入力フォームを表示するコンポーネント
 * @function
 * @param {object[]} chatItemList
 * @param {*} setChatItemList
 * @param {string} businessSummary
 * @param {*} setBusinessSummary
 * @param {*} setSegmentTextList
 * @param {*} setTagList
 * @param {string} uuid
 * @param {*} setProjectId
 * @returns {JSX.Element}
 */
export const InputBusiness = (
  chatItemList,
  setChatItemList,
  businessSummary,
  setBusinessSummary,
  t,
  lang
) => {
  /**
   * ビジネス内容入力のSubmit時処理
   * @function
   * @returns {*}
   */
  const handleBusinessSubmit = () => {
    // バリデーション
    if (businessSummary.length === 0) {
      alert(t("business.empty"));
      return;
    } else if ((lang === "en" && businessSummary.length > 60) || (lang !== "en" && businessSummary.length > 30)) {
      alert(t("business.full"));
      return;
    }

    // フォームを削除＋返答を追加
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();
    setChatItemList([
      ...chatItemListTmp,
      responseItemFormat(businessSummary, 'dialogue03', 'Add'),
    ]);
  };

  /**
   * ビジネス内容入力時、EnterキーでSubmitする処理
   * @function
   * @returns {*}
   */
  const handleKeyDown = (e) => {
    // if (e.key !== 'Enter' || e.nativeEvent.isComposing) {
    //   return;
    // }
    if (e.keyCode === 13) {
      handleBusinessSubmit();
    }
    // handleBusinessSubmit();
  };

  return (
    <>
      <input
        type="text"
        value={businessSummary}
        className="business-form"
        placeholder={t("business.placeholder")}
        onChange={(e) => setBusinessSummary(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      ></input>
      <Button
        variant="secondary-fill-round"
        onClick={() => handleBusinessSubmit()}
      >
        {t("business.submit")}
      </Button>
    </>
  );
};

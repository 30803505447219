export const chatItemParentStyle = {
  common: {
    maxWidth: '660px',
    margin: '0 auto 25px',
  },
  dialogue: {
    textAlign: 'left',
  },
  interval: {
    textAlign: 'left',
  },
  responseAdd: {
    textAlign: 'right',
    transform: 'translateX(70px)',
  },
  responsePass: {
    textAlign: 'right',
    transform: 'translateX(70px)',
  },
  form: {},
  formSegment: {
    padding: '0 30px',
  },
  segment: {
    padding: '0 30px',
  },
  DMGenerating: {
    textAlign: 'center',
  },
};

export const chatItemStyle = {
  common: {
    fontSize: '16px',
    position: 'relative',
  },
  dialogue: {
    width: '100%',
    padding: '25px 30px',
    background: '#fff',
    borderRadius: '0 18px 18px 18px',
    color: '#333333',
    textAlign: 'left',
    display: 'inline-block',
    boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
  },
  interval: {
    padding: '25px 30px',
    background: '#fff',
    borderRadius: '0 18px 18px 18px',
    color: '#333333',
    textAlign: 'left',
    display: 'inline-block',
    boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
  },
  responseAdd: {
    padding: '6px 20px',
    background: '#0068D3',
    borderRadius: '18px 18px 0px 18px',
    color: '#fff',
    textAlign: 'right',
    display: 'inline-block',
    boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
  },
  responsePass: {
    padding: '6px 20px',
    background: '#fff',
    borderRadius: '18px 18px 0px 18px',
    border: '1px solid #B81116',
    color: '#B81116',
    textAlign: 'right',
    display: 'inline-block',
    boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
  },
  form: {
    padding: '0 30px',
    textAlign: 'center',
  },
  formSegment: {},
  segment: {},
};

export const segmentStyle = {
  marginBottom: '15px',
  padding: '25px',
  background: '#fff',
  border: '#1296C8 solid 1px',
  boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
};
export const segmentPassStyle = {
  marginBottom: '15px',
  padding: '25px',
  background: '#fff',
  border: '#7D7C7D solid 1px',
  boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
};

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { dialogueContentData } from './DMListDialogueData';

import axios from 'axios';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import jsPDF from 'jspdf';
import { DMListItem } from './DMListItem';

import { Button } from '../../components/Button';
import { TextCompare } from '../../components/TextCompare';
import { sleep } from '../chat/utilities';

/**
 * DM一覧画面のコンポーネント
 * @function
 * @returns {JSX.Element}
 */
export const DMList = ({DMContentsList}) => {
  const location = useLocation();
  const [DMList, setDMList] = useState([]);
  const [isGeneratedAll, setIsGeneratedAll] = useState(false);
  const { t, i18n } = useTranslation();


  /**
   * サムネイル画像を取得する関数
   * @function
   * @param {*} DMList DMコンテンツのリスト
   * @param {*} path 取得するサムネイルのパス
   * @param {*} id DMの順番
   */
  const getThumbnail = async (DMList, DMContents, id) => {
    let i = 0;
    while (!DMContents.thumbnailImage) {
      await sleep(500);
      i++;
      if (i === 20) {
        break;
      }
    }
    const response = await fetch('/pcb/api/v1/list/thumbnail/' + DMContents.thumbnailImage);
    console.log(response);
    const blob = await response.blob();
    // console.log(blob);
    let newDM = DMList[id];
    newDM.contents.thumbnail = URL.createObjectURL(blob);
    setDMList((prevList) =>
      prevList.map((DM, key) => (key === id ? newDM : DM))
    );

    if (
      DMContentsList.length === location.state.DMContents.length
      ) {
      setIsGeneratedAll(true);
      setDMList((prevList) =>
        prevList.map((DM) => ({ ...DM, isSelect: true }))
      );
    }
  };

  // ---------- 初回レンダリング処理 ----------
  useEffect(() => {
    // console.log("最初の配列の要素数：", DMContentsList.length , location.state.DMContents.length);
    // console.log(location.state);
    let DMListInit = DMList;
    if (DMList.length === 0) {
      DMListInit = location.state.DMContents.map((DMContents, key) => {
        const num = key + 1;
        return {
          id: 'DM' + num,
          contents: {
            segmentText: DMContents.segment,
            tags: DMContents.tags,
            businessSummary: location.state.business,
          },
          isSelect: false,
        };
      });
      // console.log(DMListInit);
      setDMList(DMListInit);
    }

    // サムネイルを挿入
    // console.log(DMContentsList);
    DMContentsList.forEach((DMContents, key) => {
      getThumbnail(DMListInit, DMContents, key);
    });
  }, [DMContentsList]);

  // ---------- ズーム処理 ----------
  const [DMCol, setDMCol] = useState('three-col');
  /**
   * ズームアウトする関数
   * @function
   */
  const handleZoomOut = () => {
    if (DMCol === 'two-col') {
      setDMCol('three-col');
    }
    if (DMCol === 'three-col') {
      setDMCol('four-col');
    }
  };
  /**
   * ズームインする関数
   * @function
   */
  const handleZoomIn = () => {
    if (DMCol === 'three-col') {
      setDMCol('two-col');
    }
    if (DMCol === 'four-col') {
      setDMCol('three-col');
    }
  };

  // ---------- 選択処理 ----------
  /**
   * DMを選択したときの処理
   * @function
   * @param {*} key 選択したDMの順番
   */
  const handleSelectDM = (key) => {
    if (!isGeneratedAll) return;
    setDMList(
      DMList.map((DM, index) =>
        index === key ? { ...DM, isSelect: !DM.isSelect } : DM,
      ),
    );
  };
  /**
   * 全選択時の処理
   * @function
   */
  const handleSelectAll = () => {
    if (!isGeneratedAll) return;
    setDMList(DMList.map((DM) => ({ ...DM, isSelect: true })));
  };
  /**
   * 全選択解除時の処理
   * @function
   */
  const handleSelectAllCancel = () => {
    if (!isGeneratedAll) return;
    setDMList(DMList.map((DM) => ({ ...DM, isSelect: false })));
  };

  // ---------- DM操作 ----------

  // const handleEditDM = () => {
  //     console.log('edit');
  //     // 編集画面への移動
  // }

  /**
   * DMを複製するの処理
   * @function
   */
  const handleCopyDM = async () => {
    let DMListTmp = [];
    let sequence = null;
    DMList.forEach((DM, key) => {
      if (DM.isSelect) {
        DMListTmp.push(DM, { ...DM, id: crypto.randomUUID() });
        sequence = key;
        // DB登録処理
        axios
          .post('/pcb/api/v1/list/copy/', {
            id: location.state.projectId,
            sequence: sequence,
          })
          .then((res) => {
            // console.log('チラシイメージを複製しました');
            console.log(res);
          })
          .catch((error) => {
            console.log('AXIOS error!');
            console.log(error);
          });
      } else {
        DMListTmp.push(DM);
      }
    });
    setDMList(DMListTmp.map((DM) => ({ ...DM, isSelect: false })));
  };

  /**
   * DMを削除する処理
   * @function
   */
  const handleDeleteDM = async () => {
    let DMListTmp = [];
    let sequence = null;
    DMList.forEach((DM, key) => {
      if (DM.isSelect) {
        sequence = key;
        // DB削除処理
        axios
          .post('/pcb/api/v1/list/delete/', {
            id: location.state.projectId,
            sequence: sequence,
          })
          .then((res) => {
            // console.log('チラシイメージを削除しました');
            console.log(res);
          })
          .catch((error) => {
            console.log('AXIOS error!');
            console.log(error);
          });
      } else {
        DMListTmp.push(DM);
      }
    });
    setDMList(DMListTmp);
  };

  const [isDownload, setIsDownload] = useState(false);
  /**
   * DMをPDFダウンロードする処理
   * @function
   */
  const handleDownloadPDF = () => {
    console.log('download');
    DMList.forEach((DM) => {
      if (DM.isSelect) {
        // console.log(DM.contents.thumbnail);

        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: [100, 148],
        });

        const img = new Image();
        img.onload = function () {
          const imgWidth = 100;
          const imgHeight = (img.naturalHeight * imgWidth) / img.naturalWidth;
          pdf.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save('direct_mail.pdf');
        };
        img.src = DM.contents.thumbnail;

        setIsDownload(true);
      }
    });
  };

  /**
   * 操作ボタンのアクティブ制御する関数
   * @function
   * @param {*} operation 操作ボタン
   * @returns {boolean}
   */
  const isActive = (operation) => {
    let countSelect = 0;
    const operationArray = ['copy', 'delete', 'download'];
    DMList.forEach((DM) => (DM.isSelect ? countSelect++ : null));
    if (operation === 'edit' && countSelect === 1) {
      return true;
    } else if (
      operationArray.some((value) => value === operation) &&
      countSelect >= 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  // ---------- ダイアログの表示処理 ----------
  const [dialogueContent, setDialogueContent] = useState('');

  useEffect(() => {
    if (dialogueContent.length === 0 && location.state.firstTime) {
      setDialogueContent(getDialogue("dialogue01", i18n.language));
      location.state.firstTime = false;
    }
  }, []);

  /**
   * ダイアログをクリックしたときの処理
   * @function
   */
  const handleClickDialogue = () => {
    if (dialogueContent.nextId) {
      setDialogueContent(getDialogue(dialogueContent.nextId, i18n.language));
    } else {
      setDialogueContent(null);
      // 画面を触れるようにする
    }
  };
  
  const getDialogue = (id, lang) => dialogueContentData(lang).find((dialogue) => dialogue.id === id);

  /**
   * DMをドラッグ&ドロップしたときの処理
   * @function
   */
  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (over === null) {
        return;
      }
      if (active.id !== over.id) {
        const oldIndex = DMList.map((item) => {
          return item.id;
        }).indexOf(active.id);

        const newIndex = DMList.map((item) => {
          return item.id;
        }).indexOf(over.id);

        const newDMList = arrayMove(DMList, oldIndex, newIndex);
        setDMList(newDMList);

        //DB更新処理
        const active_sequence = active.data.current.sortable.index;
        const over_sequence = over.data.current.sortable.index;
        axios
          .post('/pcb/api/v1/list/sort/', {
            id: location.state.projectId,
            active: active_sequence,
            over: over_sequence,
          })
          .then((res) => {
            // console.log('チラシイメージをソートしました');
            console.log(res);
          })
          .catch((error) => {
            console.log('AXIOS error!');
            console.log(error);
          });
      }
    },
    [DMList]
  );
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5, // 5px ドラッグした時にソート機能を有効にする
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <div className="DMlist">
      <div className={'list ' + DMCol}>
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
          <SortableContext items={DMList}>
            {DMList.map((item, index) => (
              <DMListItem
                key={item.id}
                id={item.id}
                contents={item.contents}
                isSelect={item.isSelect}
                index={index}
                handleSelectDM={handleSelectDM}
                enabled={isGeneratedAll}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <div className="fixed-buttons">
        <TextCompare
          DMGenerateList={location.state.DMContents}
          DMContentsList={DMContentsList}
          t={t}
        />

        <div className="right-buttons">
          <div className="zoom-buttons">
            <Button
              className={DMCol === 'four-col' ? null : 'active'}
              onClick={() => handleZoomOut()}
            >
              <div>
                <img src="/images/zoom-out.svg" alt="" />
              </div>
            </Button>
            <div className="vertical-bar"></div>
            <Button
              className={DMCol === 'two-col' ? null : 'active'}
              onClick={() => handleZoomIn()}
            >
              <div>
                <img src="/images/zoom-in.svg" alt="" />
              </div>
            </Button>
          </div>
          <div className="select-buttons">
            <Button onClick={() => handleSelectAll()} style={{opacity: isGeneratedAll ? 1 : 0.3}}>{t("result.selectAll")}</Button>
            <Button onClick={() => handleSelectAllCancel()} style={{opacity: isGeneratedAll ? 1 : 0.3}}>{t("result.deselect")}</Button>
          </div>
        </div>

        <div className="left-buttons" style={{transform: "translateX(0) translateY(-50%)"}}>
        {/* <div className="left-buttons" style={{transform: isGeneratedAll ? "translateX(0) translateY(-50%)" : "translateX(-100px) translateY(-50%)"}}> */}
          <Button
            className={isActive('edit') ? 'active' : null}
            // onClick={() => handleEditDM()}
          >
            {/* <img src="/images/edit.svg" alt="" /> */}
            {t("segmentCard.edit")}
          </Button>
          <Button
            className={isActive('copy') ? 'active' : null}
            onClick={() => handleCopyDM()}
          >
            <img src="/images/copy.svg" alt="" />
            {t("result.duplicate")}
          </Button>
          <Button
            className={isActive('delete') ? 'active' : null}
            onClick={() => handleDeleteDM()}
          >
            <img src="/images/trash.svg" alt="" />
            {t("result.delete")}
          </Button>
        </div>
      </div>

      <div className="sticky-buttons">
        <div className="chatbot-container">
          <div
            className={isDownload ? 'chatbot-button bound' : 'chatbot-button'}
            onClick={() =>
              setDialogueContent(getDialogue("contact01", i18n.language))
            }
          >
            <img src="/images/robot.svg" alt="" />
          </div>
          {dialogueContent ? (
            <div
              className="chatbot-dialogue"
              onClick={() => handleClickDialogue()}
            >
              {dialogueContent.content}
              <div className="next"></div>
            </div>
          ) : null}
        </div>
        {dialogueContent ? <div className="disable-bg"></div> : null}

        <div className="download-button">
          <div style={{ textAlign: 'right' }}>
            <img
              src="/images/pixabay_logo.svg"
              alt=""
              style={{ width: '80px' }}
              className="pb-2"
            />
          </div>
          <Button
            className={isActive('download') ? 'active' : null}
            onClick={() => handleDownloadPDF()}
          >
            <img src="/images/download.svg" alt="" />
            {t("result.pdf")}
          </Button>
        </div>
      </div>
    </div>
  );
};

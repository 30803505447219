import axios from 'axios';

/**
 * GPT APIにセグメントの生成をリクエストする関数
 * @function
 * @param {string} businessSummary ビジネス内容
 * @returns {*} GPTレスポンスのPromise
 */
export const getGPTSegment = (businessSummary, categoryList, segmentTextList, lang) => {
  if (businessSummary === 'error') {
    throw 'error';
  }
  return (async () => {
    try {
      let segmentList = null;
      // セグメント名の検索
      const res1 = await postGPT("segment", lang, businessSummary, categoryList, segmentTextList, segmentList);
      console.log(res1.data);
      segmentList = JSON.parse(res1.data.choices[0].message.content);
      // console.log(segmentList);

      const res2 = await postGPT("tag", lang, businessSummary, categoryList, segmentTextList, segmentList);
      console.log(res2.data);
      return res2.data.choices[0].message.content
    } catch (error) {
      console.log('正しく取得できませんでした。');
      console.log(error);
    }
  })();
};

// ---------- カテゴリを選択する関数 ----------

export const getGPTCategory = (businessSummary, categoryList, lang) => {
  if (businessSummary === 'error') {
    throw 'error';
  }
  const apiKey = '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';
  const categoryJa =
`
#命令書:
あなたは自社のビジネスを売り込む優秀なマーケターです。
ビジネス情報に適した属性カテゴリを3つまで選択してください。

#ビジネス情報:
${businessSummary}

#属性カテゴリリスト:
${categoryList.map( item => item.category).join(",")}

#出力文(json形式):
["<属性カテゴリ1>", "<属性カテゴリ2>", "<属性カテゴリ3>"]
`;
const categoryEn =
`
#Instructions:
You are an excellent marketer who is selling your business.
Please select up to 3 attribute categories appropriate for your business information.

#Business Info:
${businessSummary}

#Attribute Category List:
${categoryList.map( item => item.category).join(",")}

#Output statement (json format):
["<Attribute Category 1>", "<Attribute Category 2>", "<Attribute Category 3>"]
`;
const categoryZh =
`
#命令书:
你是推销自己公司生意的优秀营销员。
请选择三个适合业务信息的属性类别。

#商业信息:
${businessSummary}

#属性类别列表:
${categoryList.map( item => item.category).join(",")}

#输出语句（json格式）:
["<属性类别1>", "<属性类别2>", "<属性类别3>"]
`;

  let prompt = null;
  switch (lang) {
    case "en":
      prompt = categoryEn;
      break;
    case "zh":
      prompt = categoryZh;
      break;
    case "ja":
    default:
      prompt = categoryJa;
      break;
  }
  // console.log(prompt);

  return (async () => {
    try {
      const res = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          "model": "gpt-3.5-turbo",
          messages: [{ role: 'user', content: prompt }],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
        },
      );
      console.log(res.data);
      return res.data.choices[0].message.content;
    } catch (error) {
      console.log('正しく取得できませんでした。');
      console.log(error);
    }
  })();
}


function postGPT(question, lang, businessSummary, categoryList, segmentTextList, segmentList) {

const apiKey = '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';
const segmentJa =
`
#命令書:
あなたは自社のビジネスを売り込む優秀なマーケターです。
下記の制約条件を守り、ビジネス情報に適した顧客セグメントを5件考えてください。

#ビジネス情報:
${businessSummary}

#制約条件:
・segmentには、16文字以内で顧客セグメント名を5件を考えて格納する。
${segmentTextList.length > 0
?
`
・顧客セグメント名は以下の既存セグメントリストと重複しないように作成する。

#既存セグメントリスト:
${segmentTextList.map( item => item.segment ).join(",")}
`
:
""
}

#出力文(json形式):
["<顧客セグメント名1>", "<顧客セグメント名2>", ...]
`;
const tagJa =
`
#命令書:
あなたは自社のビジネスを売り込む優秀なマーケターです。
下記の制約条件を守り、顧客セグメントそれぞれに適した属性を付与してください。

#顧客セグメント:
${segmentList && segmentList.join(',')}

#属性:
${categoryList.map( (item, key) => key + ': ' + item.tags.join(',')).join("\n")}

#制約条件:
・顧客セグメントそれぞれに対して、適した属性を1つずつ選択しtagsに格納する。適した属性がなければ省略してもよい。
・顧客セグメントが"個人"か"団体"かを判断してtypeに格納する。

#出力文(json形式):
[
{
    "segment": "${segmentList && segmentList[0]}",
    "tags": [${categoryList.map( (item, key) => '"<属性' + key + '>"' ).join(",")}],
    "type": "個人or団体"
}, ...
]
`;
const segmentEn =
`
#Instructions:.
You are an excellent marketer who is selling your business.
Please consider five customer segments that are appropriate for your business information, adhering to the following constraints.

#Business Information:
${businessSummary}

#Constraints:
Segment should contain 5 customer segment names of 16 characters or less.
${segmentTextList.length > 0
?
`
・The segment names should not overlap with the following existing segment list.

#Existing segment list:
${segmentTextList.map( item => item.segment ).join(",")}
`
:
""
}

#Output statement (json format):
["<segment name 1>", "<segment name 2>", ...]
`;
const tagEn =
`
#Instructions:
You are an excellent marketer selling your business.
Please follow the constraints below and assign the appropriate attributes to each customer segment.

#Customer Segment:
${segmentList && segmentList.join(',')}

#Attributes:
${categoryList.map( (item, key) => key + ': ' + item.tags.join(',')).join("\n")}

#Constraints:
For each customer segment, select one suitable attribute and store it in tags. If there is no suitable attribute, it may be omitted.
Determine whether the customer segment is an "individual" or a "group" and store it in type.

#Output statement (json format):
[
{
  "segment": "${segmentList && segmentList[0]}",
  "tags": [${categoryList.map( (item, key) => '"<Attribute' + key + '>"' ).join(",")}],
  "type": "individual or group"
}, ...
]
`;
const segmentZh =
`
#命令书:
你是推销自己公司生意的优秀营销员。
请遵守以下限制条件，考虑5个适合业务信息的客户群。

#商业信息:
${businessSummary}

#约束条件:
・在segment中，在16个字符以内考虑5个顾客分类名进行存储。
${segmentTextList.length > 0
?
`
・创建客户分类名称，使其不与以下现有段列表重复。

#现有段列表:
${segmentTextList.map( item => item.segment ).join(",")}
`
:
""
}

#输出语句(json格式):
["<客户分类名1>", "<客户分类名2>", ...]
`;
const tagZh =
`
#命令书：
你是推销自己公司生意的优秀营销员。
请遵守以下限制，并为每个客户段提供相应的属性。

#客户分类：
${segmentList && segmentList.join(',')}

#属性:
${categoryList.map( (item, key) => key + ': ' + item.tags.join(',')).join("\n")}

#约束条件:
・针对每个客户段，逐一选择适合的属性并存储在tags中。如果没有合适的属性，也可以省略。
・判断顾客段是“个人”还是“团体”并存储在type中。

#输出语句（json格式):
[
{
    "segment": "${segmentList && segmentList[0]}",
    "tags": [${categoryList.map( (item, key) => '"<属性' + key + '>"' ).join(",")}],
    "type": "个人or团体"
}, ...
]
`;

  let prompt = null;
  switch (question) {
    case "segment":
    default:
      switch (lang) {
        case "en":
          prompt = segmentEn;
          break;
        case "zh":
          prompt = segmentZh;
          break;
        case "ja":
        default:
          prompt = segmentJa;
          break;
      }
      break;
    case "tag":
      switch (lang) {
        case "en":
          prompt = tagEn;
          break;
        case "zh":
          prompt = tagZh;
          break;
        case "ja":
        default:
          prompt = tagJa;
          break;
      }
      break;
  }
  // console.log(prompt);

  return axios.post(
    'https://api.openai.com/v1/chat/completions',
    {
      "model": question === "segment" ? "gpt-4" : "gpt-3.5-turbo",
      messages: [{ role: 'user', content: prompt }],
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    },
  )
}
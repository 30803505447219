import { beforeDialogueFormat } from "../utilities";
import { getSegmentItem } from "../generateSegment/getSegmentItem";

export const ReloadSegment = (
    businessSummary,
    uuid,
    chatItemList,
    setChatItemList,
    segmentTextList,
    setSegmentTextList,
    setProjectId,
    activeCategories,
    setActiveCategories,
    lang
) => {
    console.log('reload');

    // 待ちダイアログを追加
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();
    setChatItemList([
    ...chatItemListTmp,
    beforeDialogueFormat(),
    ]);
    // GPT問い合わせ
    const categoryResText = "reload";
      (async () => {
        await getSegmentItem(
          businessSummary,
          uuid,
          chatItemListTmp,
          setChatItemList,
          segmentTextList,
          setSegmentTextList,
          setProjectId,
          categoryResText,
          activeCategories,
          setActiveCategories,
          lang
        );
      })();

    
    return (
        <div></div>
    )
}
import { Button } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

export const First = ({ setIsActiveOnboarding }) => {
  const { t, i18n } = useTranslation();

  const slideImageArray = [
    'images/sample/flyer01.png',
    'images/sample/flyer02.png',
    'images/sample/flyer03.png',
    'images/sample/flyer04.png',
    'images/sample/flyer05.png',
    'images/sample/flyer06.png',
  ];
  /**
   * スライドのコンポーネント
   * @function
   * @returns {JSX.Element}
   */
  const Slide = () => {
    return (
      <div className="slide-list d-flex">
        {slideImageArray.map((value, key) => {
          return (
            <div key={key} className="slide-item p-4">
              <img src={value} alt=""></img>
            </div>
          );
        })}
        {slideImageArray.map((value, key) => {
          return (
            <div key={key} className="slide-item p-4">
              <img src={value} alt=""></img>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="top first">
      <div className="language-area">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <img src="images/language.svg" alt=""></img>
            <span>{t('header.language')}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => i18n.changeLanguage('ja')}>
              {t('header.japanese')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>
              {t('header.english')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => i18n.changeLanguage('zh')}>
              {t('header.chinese')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="slide-area">
        <div className="slide d-flex">
          <Slide />
          {/* <Slide /> */}
        </div>

        <div className="title w-100 p-4">
          <p className="primary-color fw-medium m-0">{t('top.subtitle')}</p>
          <h1 className="primary-color fw-bold m-0">Coco-Simulator</h1>
        </div>
      </div>

      <div className="button-area">
        <div>
          <Button
            variant="primary-fill"
            onClick={() => setIsActiveOnboarding('Second')}
            size="big"
            className="p-2"
          >
            {t('top.next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const Second = ({ setIsActiveOnboarding }) => {
  const { t } = useTranslation();

  return (
    <div className="top second">
      <div className="title-area">
        <div className="title w-100 p-4">
          <p className="primary-color fw-medium m-0">{t('top.subtitle')}</p>
          <h1 className="primary-color fw-bold m-0">Coco-Simulator</h1>
        </div>
      </div>
      <div className="explanation-img-area">
        <img src="images/onboarding.png" alt=""></img>
      </div>
      <div className="explanation-text-area br">{t('top.explanation')}</div>

      <div className="button-area">
        <div>
          <Button
            variant="primary-line"
            onClick={() => setIsActiveOnboarding('First')}
            size="big"
            className="p-2"
          >
            {t('top.back')}
          </Button>
          <Button
            variant="primary-fill"
            onClick={() => setIsActiveOnboarding('close')}
            size="big"
            className="p-2"
          >
            {t('top.start')}
          </Button>
        </div>
        <div>
          <a
            href="https://www.konicaminolta.com/jp-ja/siteinfo/privacypolicy.html"
            target="_blank"
            rel="noreferrer"
          >
            {t('top.privacy')}
            <img src="images/new-tab.svg" alt=""></img>
          </a>
        </div>
        <div>
          <a
            href="https://accuriodx.konicaminolta.com/cocosimulatormenseki"
            target="_blank"
            rel="noreferrer"
          >
            {t('top.disclaimer')}
            <img src="images/new-tab.svg" alt=""></img>
          </a>
        </div>
      </div>
    </div>
  );
};


import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components/Button';

import Carousel from 'react-bootstrap/Carousel';

/**
 * チラシイメージ作成中のオンボーディング
 * @param {*} item 
 * @returns 
 */
export const GenerateIndicator = memo(({
    generateRate, 
    DMGenerateList, 
    projectId,
    businessSummary,
    DMContentsList,
    generateStatus,
    tipsArray,
    t
}) => {
    const navigate = useNavigate();
    const [tipsNum, setTipsNum] = useState(0);

    /**
     * インジケーターのDMアイコンを表示する関数
     * @function
     * @returns {JSX.Element}
     */
    const cardImg = () => {
        let isNotGenerate = false;
        return DMGenerateList.map((DM, key) => {
            const imagePathList = [
            'images/dm0.svg',
            'images/dm1.svg',
            'images/dm2.svg',
            'images/dm3.svg',
            ];
            if (DM.isGenerated) {
            // 作成済みのDM
            return <img src="images/dm3.svg" alt="" key={key} />;
            } else if (!isNotGenerate) {
            // 作成中のDM
            isNotGenerate = true;
            return <img src={imagePathList[generateRate]} alt="" key={key} />;
            } else {
            // まだ作成が始まっていないDM
            return <img src="images/dm0.svg" alt="" key={key} />;
            }
        });
    };

    function countGenerated(arr) {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].isGenerated === true) {
            count++;
            }
        }
        return count;
    }

    const handleNavigate = () => {
        navigate('/result', {
            state: {
            projectId: projectId,
            business: businessSummary,
            DMContents: DMGenerateList,
            DMTexts: DMContentsList,
            firstTime: true,
            },
        })
    }

    // チラシを全部作成したら自動遷移
    useEffect(() => {
        if (generateStatus === "completed") {
            handleNavigate();
        }
    }, [generateStatus]);

    const [time, setTime] = useState(0);    
    const handleSlideTime = (eventKey) => {
        const startTime = time;
        const endTime = Date.now();
        // console.log(eventKey + ": " + (endTime - startTime)); // 何ミリ秒かかったかを表示する
        setTime(endTime);
    }

    return (
        <div className={`generate-indicator modal-area ${generateStatus === "working" && "active"}`}>
        <div className='container' style={tipsArray[0] && {backgroundImage: `url('images/tips/${tipsArray[tipsNum].image}')`}}>
            <div className='indicator'>
            <p className='indicator-text'>
                {DMGenerateList.every((DM) => DM.isGenerated) ? (
                'Complete!'
                ) : (
                    <>
                    <span className="loading">
                        Now Making
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </span>
                    {`${countGenerated(DMGenerateList)}/${DMGenerateList.length}`}
                    </>
                )}
            </p>
            <p className='indicator-img'>
                <span className="card-img">{cardImg()}</span>
            </p>
            </div>
            
            <div className='tips'>
                {
                    generateStatus === "working" &&
                    <Carousel
                        indicators={false}
                        onSelect={(eventKey) => setTipsNum(eventKey)}
                        onSlide={(eventKey) => handleSlideTime(eventKey)}
                        interval={5000}>
                        {tipsArray.map((item, key) => {
                            return (
                            <Carousel.Item key={key}>
                                <img src={`images/tips/${item.image}`} alt=""></img>
                                {item.text}
                            </Carousel.Item>
                            
                            )
                        })}
                    </Carousel>
                }
            </div>

            <div style={{ textAlign: 'center' }} className='to-list'>
            {
                DMGenerateList.some((DM) => DM.isGenerated)
                ?
                <Button
                    variant={"secondary-fill"}
                    style={{ padding: 'auto 20px' }}
                    onClick={() => handleNavigate()}
                >
                    {t("indicator.go")}
                </Button>
                :
                <Button
                    variant={"disabled-fill"}
                    style={{ padding: 'auto 20px' }}
                >
                    {t("indicator.go")}
                </Button>
            }
            </div>
        </div>
        <div className='bg'></div>
        </div>
    )
});
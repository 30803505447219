import axios from 'axios';
import kuromoji from 'kuromoji';

/**
 * Pixabay APIにDMの画像取得をリクエストする関数
 * @function
 * @param {*} businessSummary ビジネス内容
 * @param {*} headline01 DMの見出しテキスト１
 * @param {*} headline02 DMの見出しテキスト２
 * @returns {JSX.Element}
 */
export const GetPixabayDMImage = (businessSummary, headline01, headline02, lang) => {
  const apiKey = '43009743-582d058e3dde3f4adcc333574';
  const queries = [businessSummary, headline01, headline02];
  // console.log(queries);

  let responses = {};
  let i = 0;

  /**
   * 検索クエリを形態素分割する関数
   * @function
   * @param {*} query 検索クエリ
   * @returns {object[]} 分割した単語リストのPromiseオブジェクト
   */
  const splitQuery = (query) => {
    return new Promise((resolve, reject) => {
      kuromoji.builder({ dicPath: '/dict' }).build((err, tokenizer) => {
        if (err) {
          console.log(err);
          return reject('reject');
        } else {
          const tokens = tokenizer.tokenize(query);
          return resolve(tokens);
        }
      });
    });
  };

  /**
   * 検索クエリから画像を取得する関数
   * @function
   * @param {*} query 検索クエリ
   * @returns {*} 取得した画像のPromiseオブジェクト
   */
  const getImage = (query) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://pixabay.com/api/?key=${apiKey}&q=${query}&lang=${lang}image_type=photo&orientation=horizontal&safesearch=true&per_page=3`,
        )
        .then((res) => {
          console.log(res.data);
          const resDataList = res.data.hits;
          const randomNum = Math.floor(Math.random() * 3);
          if (resDataList[randomNum]) {
            return resolve(resDataList[randomNum].webformatURL);
          } else {
            return resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (async () => {
    for (const query of queries) {
      try {
        // テスト
        // throw "error";
        let image = await getImage(query);
        // 結果がない場合はクエリ分割し再実施
        if (!image) {
          const words = await splitQuery(query);
          // console.log(words);
          for (const word of words) {
            if (word.pos === '名詞') {
              image = await getImage(word.surface_form);
              if (image) {
                break;
              }
            }
          }
        }

        if (image) {
          if (i === 0) {
            responses.mainImage = image;
          } else if (i === 1) {
            responses.sectionImage01 = image;
          } else if (i === 2) {
            responses.sectionImage02 = image;
          }
        }
      } catch (error) {
        console.log('image' + i + 'について正しく取得できませんでした。');
        console.log(error);
      } finally {
        // 取得できなかったらプリセット
        if (i === 0) {
          responses.mainImage ??= 'images/sample/main_sample.png';
        } else if (i === 1) {
          responses.sectionImage01 ??= 'images/sample/section_sample01.png';
        } else if (i === 2) {
          responses.sectionImage02 ??= 'images/sample/section_sample02.png';
        }
        i++;
      }
    }
    return responses;
  })();
};

import axios from 'axios';
import { dialogueData } from './dialogueData';

/**
 * 返答ダイアログを整形して返す関数
 * @function
 * @param {string} content 返答する内容
 * @param {string} nextId 次に表示するダイアログのID
 * @param {string} operation 返答のカテゴリ
 * @returns {object}
 */
export const responseItemFormat = (content, nextId, operation) => {
  return {
    id: 'response',
    category: 'response' + operation,
    content: content,
    nextAction: 'auto',
    nextId: nextId,
  };
};

/**
 * ダイアログのローディング表示を整形して返す関数
 * @function
 * @returns {object}
 */
export const beforeDialogueFormat = () => {
  return {
    id: 'interval',
    category: 'interval',
    content: (
      <span className="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    ),
    nextAction: null,
    nextId: null,
  };
};

/**
 * 渡したミリ秒数待機する関数
 * @function
 * @param {number} delay 待機時間(ミリ秒)
 * @returns {promise}
 */
export const sleep = (delay) => {
  return new Promise((resolve) => {
    // console.log(delay + 'ms wait......');
    setTimeout(() => resolve(), delay);
  });
};

/**
 * nextIdから新しい会話データを取得する関数
 * @function
 * @param {string} id 取得するダイアログのID
 * @returns {object} ダイアログデータ
 */
export const getDialogue = (id, lang) => {
  // console.log(id, lang);
  const arr = dialogueData(lang);
  return arr.find((dialogue) => dialogue.id === id);
}

/**
 * DBからプリセットタグを取得して整形する関数
 * @function
 */
export const dbReadTags = async (lang) => {
  const resTagList = await axios
    .get('/pcb/api/v1/chat/tags/')
    .then((res) => {
      console.log('dbReadTags done.');
      return res.data;
    })
    .catch((error) => {
      console.log('dbReadTags error!');
      console.log(error);
    });
  const categoryArr = resTagList.map((item) => item.category);
  const uniqueCategories = {};
  const uniqueCategoryArr = categoryArr.filter((item) => {
    if (!uniqueCategories[item.name]) {
      uniqueCategories[item.name] = true;
      return true;
    }
    return false;
  });
  const newTagList = uniqueCategoryArr.map((category) => {
    let tagArr = [];
    resTagList.forEach((item) => {
      if (item.category.name === category.name) {
        tagArr.push(item.tag.name);
      }
    });
    for (let i = 0; i < category.blank_number; i++) {
      tagArr.push('');
    }
    return {
      category: category.name,
      tags: tagArr,
      isActive: false,
    };
  });
  // カテゴリなしは削除
  const newTagListObj = {
    ja: newTagList.slice(1, 19),
    en: newTagList.slice(19, 37),
    zh: [newTagList[1], ...newTagList.slice(37, 54)],
  }

  return newTagListObj[lang]
};

/**
   * UUIDを作成する処理
   * @function
   * @returns {string} UUID
   */
export const createUUID = () => {
  const short = require('short-uuid');
  const uuid = short.generate();
  return uuid;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
import { Button } from '../../../components/Button';
import { responseItemFormat, beforeDialogueFormat } from '../utilities';
import { getSegmentItem } from './getSegmentItem';

import { useTranslation } from "react-i18next";

/**
 * ビジネス内容入力フォームを表示するコンポーネント。セグメントの取得も行う
 * @function
 * @param {object[]} chatItemList
 * @param {*} setChatItemList
 * @param {string} businessSummary
 * @param {*} setBusinessSummary
 * @param {*} setSegmentTextList
 * @param {*} setTagList
 * @param {string} uuid
 * @param {*} setProjectId
 * @returns {JSX.Element}
 */
export const SelectCategory = (
  chatItemList,
  setChatItemList,
  businessSummary,
  segmentTextList,
  setSegmentTextList,
  uuid,
  setProjectId,
  categories,
  setCategories,
  activeCategories,
  setActiveCategories,
  lang,
  t
) => {
  /**
   * ビジネス内容入力のSubmit時処理
   * @function
   * @returns {*}
   */
  const handleBusinessSubmit = (isSelect) => {
    let categoryResText = "";
    let activeArr = categories;
    if (isSelect === 'selected') {
      activeArr = categories.filter(item => item.isActive === true)
      setActiveCategories(activeArr);
      categoryResText = activeArr.map(item => item.category).join(', ');
    } else if (isSelect === 'unselected') {
      setActiveCategories(categories);
      categoryResText = t("category.omakase");
    } else {
      return;
    }

    // フォームを削除＋返答を追加
    let chatItemListTmp = chatItemList;
    chatItemListTmp.pop();
    setChatItemList([
      ...chatItemListTmp,
      responseItemFormat(categoryResText, 'dialogue03_2', 'Add'),
      beforeDialogueFormat(),
    ]);

    (async () => {
      await getSegmentItem(
        businessSummary,
        uuid,
        chatItemListTmp,
        setChatItemList,
        segmentTextList,
        setSegmentTextList,
        setProjectId,
        categoryResText,
        activeCategories = activeArr,
        setActiveCategories,
        lang
      );
    })();
  };
  
  const handleClickCategory = (key) => {
    if (key === null) return;
    const newCategories = [...categories];
    newCategories[key].isActive = !newCategories[key].isActive;
    setCategories(newCategories);
  }

  return (
    <>
      <div className={`form-category-list`}>
        {
          categories.filter(item => item.isActive === true).length < 3
          ? (
            categories.map((item, key) => {
              return (
                <Button
                  key={key}
                  variant={item.isActive ? 'secondary-fill' : 'secondary-line'}
                  onClick={() => handleClickCategory(key)}
                >
                  {item.category}
                  <img src="images/check.svg" alt=""></img>
                </Button>
              )
            })
          ) : (
            categories.map((item, key) => {
              return (
                <Button
                  key={key}
                  variant={item.isActive ? 'secondary-fill' : 'disabled-line'}
                  onClick={() => handleClickCategory(item.isActive ? key : null)}
                >
                  {item.category}
                  <img src="images/check.svg" alt=""></img>
                </Button>
              )
            })
          )
        }
      </div>
      <div className='form-category-button'>
        {
          categories.some(item => item.isActive === true)
          ? (
            <Button
              variant="secondary-fill-round"
              onClick={() => handleBusinessSubmit("selected")}
            >
              {t("category.submit")}
            </Button>
          ) : (
            <Button
              variant="disabled-fill-round"
              onClick={() => handleBusinessSubmit(null)}
            >
              {t("category.submit")}
            </Button>
          )
        }
        <Button
          variant="secondary-line-round"
          onClick={() => handleBusinessSubmit("unselected")}
        >
          {t("category.omakase")}
        </Button>
      </div>
    </>
  );
};

import { useState, useEffect, memo } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import axios from 'axios';

import { GenerateDM } from './GenerateDM';
import { Button } from '../../../components/Button';
import { sleep } from '../utilities';
// import { handleEdit, handleDeleteTag, handleAddTag } from '../editSegmentCard';


/**
 * DM作成タブを表示するコンポーネント
 * @function
 * @param {*} businessSummary ビジネス内容
 * @param {*} chatItemList チャット欄表示用のリスト
 * @param {*} setChatItemList
 * @param {*} DMGenerateList DM作成するセグメントのリスト
 * @param {*} setDMGenerateList
 * @param {*} DMPassList DM作成しないセグメントのリスト
 * @param {*} setDMPassList
 * @param {*} DMGenBtnIsActive DM作成ボタン押下を感知する真偽値
 * @param {*} setDMGenBtnIsActive
 * @returns {JSX.Element}
 */
export const CardList = ({
  businessSummary,
  chatItemList,
  setChatItemList,
  DMGenerateList,
  setDMGenerateList,
  DMPassList,
  setDMPassList,
  DMGenBtnIsActive,
  setDMGenBtnIsActive,
  setGenerateRate,
  setDMContentsList,
  handleAddGenList,
  setHandleAddGenList,
  setGenerateStatus,
  DMListOpen,
  setDMListOpen,
  lang,
  t
}) => {

  /**
   * DM作成リストの開閉制御
   * @function
   */
  const DMListHandle = () => {
    setDMListOpen((prev) => !prev);
    setCurrentListIsAdded(true);
  };

  const [currentListIsAdded, setCurrentListIsAdded] = useState(true);
  /**
   * DM作成リストとパスリストの表示制御
   * @function
   */
  const handleToggleList = () => {
    setCurrentListIsAdded((prev) => !prev);
  };

  /**
   * セグメントのリスト間移動処理
   * @function
   * @param {*} operation 操作
   * @param {*} key 対象のセグメント
   */
  const handleMoveSegment = (operation, key) => {
    let moveSegment = {};
    if (operation === 'pass') {
      let newDMGenerateList = DMGenerateList;
      const moveSegmentList = newDMGenerateList.splice(key, 1);
      moveSegment = moveSegmentList[0];
      setDMGenerateList(newDMGenerateList);
      setDMPassList((prev) => [...prev, moveSegment]);
    } else if (operation === 'add') {
      let newDMPassList = DMPassList;
      const moveSegmentList = newDMPassList.splice(key, 1);
      moveSegment = moveSegmentList[0];
      setDMPassList(newDMPassList);
      setDMGenerateList((prev) => [...prev, moveSegment]);
      setHandleAddGenList(prev => !prev);
    }
    //DB処理
    axios
      .post('/pcb/api/v1/chat/segments/move/', {
        operation: operation,
        segment_id: moveSegment.segmentId,
      })
      .then((res) => {
        console.log('handleMoveSegment done.');
        console.log(res);
      })
      .catch((error) => {
        console.log('handleMoveSegment error!');
        console.log(error);
      });
  };

  
  
  /**
   * DM作成リストのコンポーネント
   * @function
   * @returns {JSX.Element}
  */
  const DMGenerateListIndicator = () => {

   const [isEditableAtList, setIsEditableAtList] = useState(false);
   const [editingData, setEditingData] = useState({});
   
   const handleEdit = (key, DM, lang, t) => {
     if (isEditableAtList) {
        // アラート
        let alertText = '';
        if (editingData.segment.length === 0) {
        alertText = alertText.concat(t("segmentCard.emptySegment"));
        } else if (editingData.segment.length > (lang === "en" ? 40 : 16)) {
        alertText = alertText.concat(t("segmentCard.fullSegment"));
        }
        if (editingData.tags.some((tag) => tag.length > (lang === "en" ? 40 : 16))) {
        alertText = alertText.concat(t("segmentCard.fullTag"));
        }
        if (alertText) {
        alert(alertText);
        return;
        }
        // 空タグの削除
        let withoutNullTags = [];
        editingData.tags.forEach((tag) =>
            tag ? withoutNullTags.push(tag) : null,
        );
        let newEditingData = {...editingData, tags: withoutNullTags};

        //DB処理
        axios
        .post('/pcb/api/v1/chat/segments/update/', {
          segment_id: DMGenerateList[key].segmentId,
          segment: newEditingData.segment,
          tags: newEditingData.tags,
        })
        .then((res) => {
          console.log('handleUpdateSegment done.');
          console.log(res);

          let updateSegment = [...DMGenerateList];
          updateSegment[key] = newEditingData;
          setDMGenerateList(updateSegment);
          setEditingData({});
        })
        .catch((error) => {
          console.log('handleUpdateSegment error!');
          console.log(error);
          return;
        });

      } else {
        setEditingData(DM);
      }
      setIsEditableAtList((prev) => !prev);
   }
  
   const handleDeleteTag = (key) => {
    let newTags = [...editingData.tags];
    newTags.splice(key, 1);
    setEditingData({
      ...editingData,
      tags: newTags,
    });
   }
  
   const handleAddTag = (key) => {
    let newTags = [...editingData.tags];
    newTags.push('');
    setEditingData({
      ...editingData,
      tags: newTags,
    });
   }
  
   // activeKeyを制御するためのstate
   const [activeKey, setActiveKey] = useState(null);
   const handleAccordionChange = (newActiveKey) => {
    if (newActiveKey !== null || newActiveKey !== activeKey) {
      setIsEditableAtList(false);
    }
    setActiveKey(newActiveKey);
   };

    return (
      <div className="DM-generate-list">
        <Accordion defaultActiveKey="0" activeKey={activeKey} onSelect={handleAccordionChange}>
          {DMGenerateList.map((DM, key) => {
            return (
              <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>
                  <img src="images/person.svg" alt="" />
                  {isEditableAtList && key === activeKey ? (
                    <>
                    </>
                  ) : (
                    <>
                      <span>{DM.segment}</span>
                    </>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  <div className='segment'>
                    {isEditableAtList && key === activeKey ? (
                      <input
                          contentEditable
                          value={editingData.segment}
                          onChange={(e) => setEditingData({...editingData, segment: e.target.value})}
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="tag">
                    {isEditableAtList ? (
                      <>
                        {editingData.tags.map((tag, key) => (
                          <div key={key} className="tag-wrap active">
                            <input
                              className="tag-text"
                              contentEditable
                              value={tag}
                              onChange={(e) =>
                                setEditingData({
                                  ...editingData,
                                  tags: editingData.tags.map((tag, i) =>
                                    i === key ? e.target.value : tag,
                                  ),
                                })
                              }
                            />
                            <div
                              className="tag-delete"
                              onClick={
                                () => handleDeleteTag(key)
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        ))}
                        <div className="add-tag-wrap">
                          <div
                            className={
                              DM.tags.length >= 5
                                ? 'add-tag disabled'
                                : 'add-tag'
                            }
                            onClick={
                              () => handleAddTag(key)
                            }
                          >
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </>
                    ) : (
                      DM.tags.map((tag, key) => (
                        <div key={key} className="tag-wrap">
                          <div className="tag-text">{tag}</div>
                        </div>
                      ))
                    )}

                  </div>

                  <div className="item-footer">
                    <Button className="edit-button" onClick={() => handleEdit(key, DM, lang, t)}>
                      {isEditableAtList ? (
                        <p>{t("segmentCard.update")}</p>
                      ) : (
                        <p>{t("segmentCard.edit")}</p>
                      )}
                    </Button>
                    <Button className="move-button" onClick={() => handleMoveSegment('pass', key)}>
                      {t("segmentCard.pass")}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
        <div className="DM-blank-list">
          {[...Array(8 - DMGenerateList.length)].map((item, key) => (
            <p key={key} style={{ backgroundColor: '#ffffff40' }}></p>
          ))}
        </div>
      </div>
    );
  };

  /**
   * DMパスリストのコンポーネント
   * @function
   * @returns {JSX.Element}
   */
  const DMPassListIndicator = () => {
    return (
      <div
        className={
          DMPassList.length > 8 ? 'DM-pass-list scroll' : 'DM-pass-list'
        }
      >
        <Accordion defaultActiveKey="0">
          {DMPassList.map((DM, key) => {
            return (
              <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>
                  <img src="images/person.svg" alt="" />
                  <span>{DM.segment}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tag">
                    {DM.tags.map((tag, key) => (
                      <div key={key} className="tag-wrap">
                        <div className="tag-text">{tag}</div>
                      </div>
                    ))}
                  </div>
                  <div className="item-footer">
                    <Button
                      className={`move-button ${DMGenerateList.length < 8 ? '' : 'disabled'}`}
                      onClick={() => handleMoveSegment('add', key)}
                    >
                      {t("cardList.reAdd")}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    );
  };
  
  
  const [isAddList, setIsAddList] = useState(false);
  const handleAddList = async () => {
    setIsAddList(true);
    await sleep(1000);
    setIsAddList(false);
  }

  useEffect(() => {
    if (!DMGenBtnIsActive) return;
    handleAddList();
  }, [handleAddGenList]);
  
  function DMGenerateHeader({isAddList}) {
    return (
      <div className="DM-generate-header-wrap">
        <div className={`DM-generate-header ${lang === "en" && "en"}`} onClick={() => DMListHandle()}>
          <div className={DMListOpen ? 'arrow open' : 'arrow'}></div>
          <p>{t("cardList.addList")}</p>
          <div className="badge">
            {DMGenerateList.length}
            <span className={isAddList ? 'badge-anime' : ''}></span>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <div
        className="DM-generate-container"
        style={{
          right:
            DMListOpen && DMGenBtnIsActive
              ? '0px'
              : DMGenBtnIsActive
                ? '-340px'
                : '-380px',
        }}
      >
        <DMGenerateHeader isAddList={isAddList} />

        <div className="DM-generate-contents">
          {currentListIsAdded ? (
            <DMGenerateListIndicator />
          ) : (
            <DMPassListIndicator />
          )}

          <div>
            <div>
              <Button
                className="toggle-list"
                variant="clear-line-round"
                onClick={() => handleToggleList()}
              >
                {currentListIsAdded
                  ? t("cardList.passList")
                  : t("cardList.back")}
              </Button>
            </div>
            <div className="mt-3 submit-button-small">
              <GenerateDM
                businessSummary={businessSummary}
                chatItemList={chatItemList}
                setChatItemList={setChatItemList}
                DMGenerateList={DMGenerateList}
                setDMGenerateList={setDMGenerateList}
                DMPassList={DMPassList}
                setDMPassList={setDMPassList}
                DMGenBtnIsActive={DMGenBtnIsActive}
                setDMGenBtnIsActive={setDMGenBtnIsActive}
                setGenerateRate={setGenerateRate}
                setDMContentsList={setDMContentsList}
                setGenerateStatus={setGenerateStatus}
                lang={lang}
                t={t}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="submit-button"
        style={{ display: DMGenBtnIsActive ? 'block' : 'none' }}
      >
        <GenerateDM
          businessSummary={businessSummary}
          chatItemList={chatItemList}
          setChatItemList={setChatItemList}
          DMGenerateList={DMGenerateList}
          setDMGenerateList={setDMGenerateList}
          DMPassList={DMPassList}
          setDMPassList={setDMPassList}
          DMGenBtnIsActive={DMGenBtnIsActive}
          setDMGenBtnIsActive={setDMGenBtnIsActive}
          setGenerateRate={setGenerateRate}
          setDMContentsList={setDMContentsList}
          setGenerateStatus={setGenerateStatus}
          lang={lang}
          t={t}
        />
      </div>
    </>
  );
};

import axios from "axios";

import { getGPTSegment, getGPTCategory } from "./GetGPTSegmentItem";
import { responseItemFormat, getDialogue } from '../utilities';
import { AddChatItem } from "../AddChatItem";

export const getSegmentItem = async (
  businessSummary,
  uuid,
  chatItemListTmp,
  setChatItemList,
  segmentTextList,
  setSegmentTextList,
  setProjectId,
  categoryResTest,
  activeCategories,
  setActiveCategories,
  lang
) => {
    const gptSegmentRes = await callGptAPI(businessSummary, activeCategories, segmentTextList, setSegmentTextList, setActiveCategories, lang);

    // 初回生成
    if (categoryResTest !== "reload") {
      if (gptSegmentRes) {
        await dbCreateProject(businessSummary, uuid, gptSegmentRes, setProjectId);
        setChatItemList([
          ...chatItemListTmp,
          responseItemFormat(categoryResTest, 'dialogue03_2', 'Add'),
        ]);
      } else {
        setChatItemList([
          ...chatItemListTmp,
          responseItemFormat(categoryResTest, 'dialogue03e', 'Add'),
        ]);
      }
    // 再生成
    } else {
      if (gptSegmentRes) {
        await dbCreateProject(businessSummary, uuid, gptSegmentRes, setProjectId);
        setChatItemList([
          ...chatItemListTmp,
          getDialogue("segmentReloadSuccess", lang)
        ]);
      } else {
        setChatItemList([
          ...chatItemListTmp,
          getDialogue("segmentReloadError", lang)
        ]);
      }
    }
}

/**
 * GPT APIから取得したセグメントをセットする関数
 * @function
 * @returns {*} GPTのレスポンステキスト
 */
const callGptAPI = async (businessSummary, activeCategories, segmentTextList, setSegmentTextList, setActiveCategories, lang) => {
  try {
    // 本番
    let categoryList = activeCategories;
    // console.log(categoryList);
    // おまかせの場合は先にカテゴリを取得
    if (categoryList.length > 3) {
      const gptCategoryRes = await getGPTCategory(businessSummary, categoryList, lang);
      const parseCategoryRes = JSON.parse(gptCategoryRes);
      // console.log(parseCategoryRes);
      categoryList = categoryList.filter(item => parseCategoryRes.includes(item.category));
      // console.log(categoryList);
      setActiveCategories(categoryList);
    }
    // セグメント取得
    const gptSegmentRes = await getGPTSegment(businessSummary, categoryList, segmentTextList, lang);
    const parseSegmentRes = JSON.parse(gptSegmentRes);
    // console.log(parseSegmentRes);
    setSegmentTextList(prev => [...prev, ...parseSegmentRes]);
    return gptSegmentRes;

    // テストデータ
    // const gptSegmentRes = [
    //   {"segment": "個人ブロガー", "tags": ["男性", "20代", "単身者"], "type": "個人"},
    //   {"segment": "起業家", "tags": ["男性", "30代", "既婚子なし"], "type": "個人"},
    //   {"segment": "フリーランスのウェブデザイナー", "tags": ["女性", "40代", "既婚子あり"], "type": "個人"},
    //   {"segment": "中小規模の企業や事業所", "tags": ["男性", "20代", "単身者"], "type": "団体"},
    //   {"segment": "大企業やブランドを持つ企業", "tags": ["男性", "20代", "単身者"], "type": "団体"},
    // ]
    // console.log(gptSegmentRes);
    // setSegmentTextList(gptSegmentRes);
    // return "sample";
    
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

/**
 * ビジネス内容とGPTレスポンスをDBに保存する関数
 * @param {string} businessSummary ビジネス内容
 * @param {string} uuid UUID
 * @param {*} gptSegmentRes GPTレスポンステキスト
 */
const dbCreateProject = async (businessSummary, uuid, gptSegmentRes, setProjectId) => {
  axios
    .post('/pcb/api/v1/chat/projects/', {
      uuid: uuid,
      business_summary: businessSummary,
      gpt_response: gptSegmentRes,
    })
    .then((res) => {
      console.log('dbCreateProject done.');
      console.log(res);
      setProjectId(res.data.id);
    })
    .catch((error) => {
      console.log('dbCreateProject error!');
      console.log(error);
    });
};